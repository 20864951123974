import React from "react";
import { autobind } from "react-decoration";
import navigate from "lib/navigate";
import qs from "query-string";
import { inject, observer } from "mobx-react";

@inject("appStore")
@observer
class StudentAreaTab extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showTabs: false,
    };
  }

  @autobind
  changePageType(pageType, e) {
    e.preventDefault();
    let { location } = this.props;
    let params = {};
    params["pageType"] = pageType;
    navigate([location.pathname, qs.stringify(params)].join("?"));
    if (this.state.showTabs) {
      this.setState({
        showTabs: false,
      });
    }
  }

  @autobind
  handleShowTabs(e) {
    e.preventDefault();
    this.setState({
      showTabs: !this.state.showTabs,
    });
  }

  render() {
    let { searchForm, t, tabList } = this.props;
    let { pageType } = searchForm;
    let { showTabs } = this.state;
    return (
      <>
        <div className="main_tabSet">
          <div className="container">
            <button
              type="button"
              className="active_m"
              onClick={this.handleShowTabs}>
              {tabList.map((tab) => {
                if (tab === "studentShareReading" && pageType === "studentShareReading") {
                  return <>{"共讀書單"}</>;
                }
                return <></>;
              })}
            </button>
            <ul className="tabs" style={{ display: showTabs ? "block" : "" }}>
              {tabList.map((tab) => {
                if (tab === "studentShareReading") {
                  return (
                    <li className={pageType === "studentShareReading" ? "active" : ""}>
                      <a
                        tabIndex="0"
                        onClick={this.changePageType.bind(this, "studentShareReading")}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            this.changePageType("studentShareReading", e);
                          }
                        }}>
                        共讀書單
                      </a>
                    </li>
                  );
                }
                return <></>;
              })}
            </ul>
          </div>
        </div>
      </>
    );
  }
}

export default StudentAreaTab;
