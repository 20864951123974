import React from "react";
import client from "lib/ApolloClient";
import gql from "graphql-tag";
import { inject, observer } from "mobx-react";
import { autobind } from "react-decoration";

const setTeacherInputRecommend = gql`
  mutation setTeacherInputRecommend($form: TeacherInputRecommendForm) {
    setTeacherInputRecommend(Input: $form) {
      success
    }
  }
`;

@inject("appStore")
@observer
class TeacherInputRecommendEbookComp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      auth: props.readerStore.auth,
      title: "",
      author: "",
      isbn: "",
      publisher: "",
      publishDate: "",
    };
  }

  componentDidMount() {}

  UNSAFE_componentWillReceiveProps(props) {}

  clearAll() {
    this.setState({
      title: "",
      author: "",
      isbn: "",
      publisher: "",
      publishDate: "",
    });
  }

  @autobind
  sendRecommend(e) {
    e.preventDefault();
    if (this.state.title === "") {
      alert("請輸入題名");
    } else if (this.state.isbn === "") {
      alert("請輸入ISBN");
    } else {
      client.jumperrwdClient
        .mutate({
          mutation: setTeacherInputRecommend,
          variables: {
            form: {
              title: this.state.title,
              author: this.state.author,
              isbn: this.state.isbn,
              publisher: this.state.publisher,
              publishDate: this.state.publishDate,
            },
          },
        })
        .then((res) => {
          if (res.data.setTeacherInputRecommend.success) {
            alert("推薦成功");
            this.clearAll();
          } else {
            alert("推薦失敗");
          }
        });
    }
  }

  render() {
    return (
      <>
        <div className="list_all">
          <div className="content">
            <form action="">
              <div className="form_grid form">
                <div className="form_grp">
                  <label htmlFor="title" className="form_title">
                    <span className="orange">*</span>題名
                  </label>
                  <div className="form_content">
                    <input
                      id="title"
                      name="stitle"
                      type="text"
                      value={this.state.title}
                      onChange={(e) => this.setState({ title: e.target.value })}
                    />
                  </div>
                </div>
                <div className="form_grp">
                  <label htmlFor="author" className="form_title">
                    著者
                  </label>
                  <div className="form_content">
                    <input
                      id="author"
                      name="stitle"
                      type="text"
                      value={this.state.author}
                      onChange={(e) =>
                        this.setState({ author: e.target.value })
                      }
                    />
                  </div>
                </div>
                <div className="form_grp">
                  <label htmlFor="isbn" className="form_title">
                    <span className="orange">*</span>ISBN
                  </label>
                  <div className="form_content">
                    <input
                      id="isbn"
                      name="stitle"
                      type="text"
                      value={this.state.isbn}
                      onChange={(e) => this.setState({ isbn: e.target.value })}
                    />
                  </div>
                </div>
                <div className="form_grp">
                  <label htmlFor="publisher" className="form_title">
                    出版者
                  </label>
                  <div className="form_content">
                    <input
                      id="publisher"
                      name="stitle"
                      type="text"
                      value={this.state.publisher}
                      onChange={(e) =>
                        this.setState({ publisher: e.target.value })
                      }
                    />
                  </div>
                </div>
                <div className="form_grp">
                  <label htmlFor="publishDate" className="form_title">
                    出版年
                  </label>
                  <div className="form_content">
                    <input
                      id="publishDate"
                      name="stitle"
                      type="text"
                      value={this.state.publishDate}
                      onChange={(e) =>
                        this.setState({ publishDate: e.target.value })
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="questionnaire">
                <div className="btn_grp">
                  <button
                    className="btn btn-reset"
                    onClick={(e) => {
                      e.preventDefault();
                      this.clearAll();
                    }}>
                    取消
                  </button>
                  <button
                    className="btn btn-submit"
                    onClick={this.sendRecommend}>
                    確認送出
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </>
    );
  }
}

export default TeacherInputRecommendEbookComp;
