import React from "react";
import { inject, observer } from "mobx-react";
import gql from "graphql-tag";
import client from "lib/ApolloClient";
import { ApolloProvider, Query } from "react-apollo";
import ReactLoading from "react-loading";
import navigate from "lib/navigate";

const getEbookNoticeCount = gql`
  query getEbookNoticeCount {
    getEbookNoticeCount {
      count
    }
  }
`;

@inject("appStore")
@observer
class EbookNoticeMyNoticeComp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      auth: props.readerStore.auth,
    };
  }

  componentDidMount() {}

  UNSAFE_componentWillReceiveProps() {}

  render() {
    return (
      <>
        <div className="list_all">
          <div className="detail_block">
            <div className="info">
              <ApolloProvider client={client.jumperrwdClient}>
                <Query query={getEbookNoticeCount} fetchPolicy="network-only">
                  {({ loading, error, data, refetch }) => {
                    if (loading)
                      return (
                        <div style={{ textAlign: "center" }}>
                          <ReactLoading
                            type="cylon"
                            height={"10%"}
                            width={"20%"}
                            color="#005D98"
                          />
                        </div>
                      );
                    if (error) return `Error!${error.message}`;
                    if (this.state.refetch === null) {
                      this.setState({ refetch: refetch });
                    }

                    if (data.getEbookNoticeCount) {
                      let { count = 0 } = data.getEbookNoticeCount;
                      return (
                        <>
                          {count === 0 && (
                            <>
                              <p>未有通知</p>
                            </>
                          )}
                          {count > 0 && (
                            <>
                              <p>
                                您有{count}本預約書可借，請至
                                <a
                                  href="javascript:;"
                                  onClick={() => {
                                    navigate(
                                      "/custList?listType=reserve-canlend&pageType=ebookHistory"
                                    );
                                  }}
                                  onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                      navigate(
                                        "/custList?listType=reserve-canlend&pageType=ebookHistory"
                                      );
                                    }
                                  }}>
                                  預約可借
                                </a>
                                確認
                              </p>
                            </>
                          )}
                        </>
                      );
                    }
                    return "";
                  }}
                </Query>
              </ApolloProvider>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default EbookNoticeMyNoticeComp;
