import gql from "graphql-tag";
import client from "lib/ApolloClient";
import { inject, observer } from "mobx-react";
import React from "react";
import { autobind } from "react-decoration";

const uploadTeacherBatchRecommend = gql`
  mutation uploadTeacherBatchRecommend($Input: TeacherBatchRecommendForm) {
    uploadTeacherBatchRecommend(Input: $Input) {
      success
      errorType
      message
    }
  }
`;

@inject("appStore")
@observer
class TeacherBatchRecommendEbookComp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      auth: props.readerStore.auth,
    };
  }

  componentDidMount() {}

  UNSAFE_componentWillReceiveProps(props) {}

  @autobind
  uploadFile(file) {
    return client.jumperrwdClient.mutate({
      mutation: uploadTeacherBatchRecommend,
      variables: {
        Input: {
          file: file,
        },
      },
    });
  }

  render() {
    let { t } = this.props;
    return (
      <div>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            const form = e.target;
            let file = e.target[0].files[0];
            this.uploadFile(file)
              .then((res) => {
                const result = res.data.uploadTeacherBatchRecommend;
                if (result.success) {
                  alert("上傳成功");
                  form.reset();
                  return;
                }
                if (result.message) {
                  alert(result.message);
                }
              })
              .catch((err) => {
                console.log(err);
              });
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexWrap: "wrap",
              gap: "16px",
              margin: "16px",
              padding: "16px",
            }}
          >
            <div>
              <div>請上傳Excel檔</div>
              <a href={"/file/data/batch_recommend_sample.xlsx"} download>
                ※批次薦購範例xls格式下載
              </a>
            </div>
            <div>
              <input
                type={"file"}
                accept={".xlsx"}
                style={{
                  position: "relative",
                  opacity: "1",
                  fontSize: "1em",
                }}
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <button className={"btn btn-reset"} type={"reset"}>
              {t("jumperrwd.common.cancel")}
            </button>
            <button className={"btn btn-submit"} type={"submit"}>
              {t("jumperrwd.common.submit")}
            </button>
          </div>
        </form>
      </div>
    );
  }
}

export default TeacherBatchRecommendEbookComp;
