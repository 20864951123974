import React from "react";
import PropTypes from "prop-types";
import client from "lib/ApolloClient";
import gql from "graphql-tag";
//import moment from 'moment';
import { arrangeData } from "components/list/action/action-util";
//import Link from 'next/link';
import View from "components/list/view/View";
import { autobind } from "react-decoration";
import ReactHtmlParser from "react-html-parser";
import EbookHistoryShareExportModal from "components/cust/EbookHistoryShareExportModal";
import Link from "lib/Link";
import rr from "lib/ResourceRelated";

const setJournalAPIAct = gql`
  mutation setJournalAPIAct($form: JournalApiActForm) {
    setJournalAPIAct(Input: $form) {
      success
      message
      errorType
      sendURL
    }
  }
`;

class EbookHistoryDisplayComp extends View {
  constructor(props) {
    super(props);

    this.state = {
      modalVisible: false,
      platformMap: {},
      browserMap: {},
      exportModalVisible: false,
    };
  }

  componentDidMount() {}

  @autobind
  getDisplayDataByField(display = [], fieldName = "") {
    let seqIndex = display
      .map(function (display) {
        return display.field;
      })
      .indexOf(fieldName);
    return display[seqIndex] || {};
  }

  @autobind
  journalAPIAct(journalID, resourceType, name, code, type, resourceName) {
    if (this.props.readerStore.auth) {
      client.jumperrwdClient
        .mutate({
          mutation: setJournalAPIAct,
          variables: {
            form: {
              journalID: journalID,
              resourceType: resourceType,
              code: code,
              type: type,
            },
          },
        })
        .then((res) => {
          if (res.data.setJournalAPIAct.success) {
            if (
              res.data.setJournalAPIAct.sendURL &&
              res.data.setJournalAPIAct.sendURL !== ""
            ) {
              if (res.data.setJournalAPIAct.errorType === 10410) {
                alert(this.props.t("jumperrwd.journalAPIAct.alreadyLend"));
              } else if (res.data.setJournalAPIAct.errorType === 500) {
                alert(
                  this.props.t("jumperrwd.journalAPIAct." + name + "Success")
                );
              }
              if (
                this.props.refetch !== null &&
                this.props.refetch !== undefined
              ) {
                this.props.refetch();
              }
              window.open(res.data.setJournalAPIAct.sendURL, "_blank");
            } else {
              alert(
                this.props.t("jumperrwd.journalAPIAct." + name + "Success")
              );
              if (
                this.props.refetch !== null &&
                this.props.refetch !== undefined
              ) {
                this.props.refetch();
              }
            }
          } else {
            let failedCode = res.data.setJournalAPIAct.message;
            if (res.data.setJournalAPIAct.errorType === 10610) {
              alert(
                this.props.t(
                  "jumperrwd.journalAPIAct." + failedCode + "ReturnTimeFailed",
                  {
                    resourceName: resourceName,
                  }
                )
              );
            } else {
              alert(
                this.props.t("jumperrwd.journalAPIAct." + name + "Failed", {
                  resourceName: resourceName,
                })
              );
            }
          }
        });
    } else {
      if (this.props.isPage) {
        this.props.appStore.openLogin();
      } else {
        this._login.wrappedComponent._openLogin();
      }
    }
  }

  render() {
    let {
      displayConfig,
      data,
      resourceData,
      rowStyle,
      serialNo,
      handleClick,
      i18n,
      variables,
      t,
    } = this.props;

    const arrangedData = arrangeData(data);

    let {
      title,
      imgUrl = "",
      freeReadCode = "",
      returnCode = "",
      cancelReserveCode = "",
      borrowCode = "",
      journalID = "",
      tID = "",
      sourceName = "",
      sourceEnName = "",
    } = arrangedData;

    let { searchForm } = variables;
    let { listType = "lend-current" } = searchForm;

    let { language } = i18n;
    let typeList = [];
    let freeReadOut = [];

    let journalURLList = resourceData.journalURLList;
    let journalAttachList = resourceData.journalAttachList;
    let cateList = resourceData.cateList;
    let standardno = resourceData.standardno;
    let impactFactor = resourceData.impactFactor;
    let srValue = resourceData.srValue;

    let theSourceName =
      language === "en" && sourceName !== "" ? sourceEnName : sourceName;

    if (freeReadCode.includes(";")) {
      typeList = freeReadCode.split(";")[1].split(",");
      freeReadCode = freeReadCode.split(";")[0];
    }

    if (freeReadCode !== "") {
      if (typeList.length > 0) {
        typeList.map((type) => {
          freeReadOut.push(
            <button
              className="btn_borrow"
              type="button"
              onClick={(e) => {
                e.preventDefault();
                this.journalAPIAct(
                  journalID,
                  "ebook",
                  "freeRead",
                  freeReadCode,
                  type,
                  theSourceName
                );
              }}>
              <a>{t("jumperrwd.ebBasicApi.freeRead" + type)}</a>
            </button>
          );
          return "";
        });
      } else {
        freeReadOut.push(
          <button
            className="btn_borrow"
            type="button"
            onClick={(e) => {
              e.preventDefault();
              this.journalAPIAct(
                journalID,
                "ebook",
                "freeRead",
                freeReadCode,
                "",
                theSourceName
              );
            }}>
            <a>{t("jumperrwd.ebBasicApi.freeRead")}</a>
          </button>
        );
      }
    }

    if (imgUrl === "") {
      imgUrl = "/file/images/icon_book.svg";
    }

    return (
      <>
        <div className={`list_block ${rowStyle}`}>
          <div className="list_num">
            <input
              title={t("jumperrwd.common.selectJournal")}
              type="checkbox"
              value={journalID}
              checked={this.props.isChecked(journalID)}
              onChange={(e) => {
                handleClick("addCheckedId", {
                  id: journalID,
                  tID: tID,
                  checked: e.target.checked,
                });
              }}
            />
            {serialNo}
          </div>
          <div className="function_btn">
            <button
              type="button"
              tabIndex="0"
              title={t("jumperrwd.common.shareAndExport")}
              onClick={() => this.setState({ exportModalVisible: true })}>
              <i className="i_share"></i>
              <span>{t("jumperrwd.common.shareAndExport")}</span>
            </button>
          </div>
          <div className="sort_icon">
            <div className="pic">
              <img
                src={imgUrl}
                alt={t("jumperrwd.common.book")}
                onError={(e) => {
                  e.target.src = "/file/images/icon_book.svg";
                }}
              />
            </div>
          </div>
          <div className="list_data">
            <h3>
              {journalID !== "" ? (
                <a
                  tabIndex="0"
                  onClick={(e) => {
                    e.preventDefault();
                    handleClick("journal", {
                      id: journalID,
                      resourceType: "ebook",
                    });
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleClick("journal", {
                        id: journalID,
                        resourceType: "ebook",
                      });
                    }
                  }}>
                  {ReactHtmlParser(title)}
                </a>
              ) : (
                <a href="javascript:;">{ReactHtmlParser(title)}</a>
              )}
            </h3>
            <ul>
              {data.map((item, keys) => {
                let fieldConfig = this.getDisplayDataByField(
                  displayConfig,
                  item.key
                );
                if (
                  item.key !== "bookid" &&
                  item.key !== "dbID" &&
                  item.key !== "tID" &&
                  item.key !== "journalID" &&
                  item.key !== "title" &&
                  item.key !== "imgUrl" &&
                  item.key !== "freeReadCode" &&
                  item.key !== "returnCode" &&
                  item.key !== "cancelReserveCode" &&
                  item.key !== "borrowCode" &&
                  !(item.key === "sourceEnName" && language !== "en") &&
                  !(item.key === "sourceName" && language === "en")
                ) {
                  let outTemp = [];
                  if (item.key === "connrange") {
                    outTemp.push(t("jumperrwd.connrange.name." + item.value));
                  } else if (item.key === "publication_type") {
                    outTemp.push(
                      t("jumperrwd.publication_type.name." + item.value)
                    );
                  } else if (item.key === "notes") {
                    outTemp.push(ReactHtmlParser(item.value));
                  } else if (item.key === "eb_journal_url") {
                    let journalURLTemp = [];
                    if (journalURLList) {
                      journalURLList.map((journalURL, k) => {
                        if (k !== 0) {
                          journalURLTemp.push("、");
                        }
                        journalURLTemp.push(
                          <Link
                            href={journalURL.url}
                            target={journalURL.openWin === "0" ? "_blank" : ""}>
                            {journalURL.showName !== ""
                              ? journalURL.showName
                              : "(" + t("jumperrwd.common.noName") + ")"}
                          </Link>
                        );
                        return "";
                      });
                    }
                    if (journalURLTemp.length > 0) {
                      outTemp.push(journalURLTemp);
                    }
                  } else if (item.key === "ebattach") {
                    let journalAttachTemp = [];
                    if (journalAttachList) {
                      journalAttachList.map((journalAttach, k) => {
                        let showName = journalAttach.showName
                          ? journalAttach.showName
                          : t("jumperrwd.common.file");
                        let attachPath = journalAttach.attachPath
                          ? journalAttach.attachPath
                          : "";
                        let fileTypePhoto = rr.getFileTypePhoto(attachPath);
                        if (k !== 0) {
                          journalAttachTemp.push("、");
                        }
                        let ebattachTemp = [];
                        if (fileTypePhoto !== "") {
                          ebattachTemp.push(
                            <>
                              &nbsp;
                              <img
                                src={fileTypePhoto}
                                alt="檔案類型"
                                border="0"
                              />
                            </>
                          );
                        }
                        journalAttachTemp.push(
                          <Link href={"/file/downloadFile/" + attachPath}>
                            {showName}
                            {ebattachTemp}
                          </Link>
                        );
                        return "";
                      });
                    }
                    if (journalAttachTemp.length > 0) {
                      outTemp.push(journalAttachTemp);
                    }
                  } else if (
                    item.key === "ft_start_date" ||
                    item.key === "ft_end_date"
                  ) {
                    let timeValue = item.value;
                    if (timeValue.toLowerCase() === "current") {
                      timeValue = t("jumper.common.current");
                    }
                    outTemp.push(timeValue);
                  } else if (item.key === "sr") {
                    if (item.value !== "") {
                      item.value.split(",").map((sr, k) => {
                        let name = "jumperrwd.sr." + sr;
                        if (k !== 0) {
                          outTemp.push(", ");
                        }
                        outTemp.push(t(name));
                        return "";
                      });
                      outTemp.push(
                        " (" +
                          t("jumperrwd.common.srValue") +
                          ":" +
                          srValue +
                          ")"
                      );
                    }
                  } else if (item.key === "publisher") {
                    if (item.value !== "") {
                      let publisherList = item.value.split(/ |，|,|：|:|／|\//);
                      publisherList.map((p, k) => {
                        if (p !== "") {
                          if (k !== 0) {
                            outTemp.push("、");
                          }
                          outTemp.push(
                            <a
                              href="javascript:;"
                              tabIndex="0"
                              onClick={() => this.searchFieldValue("publisher", p)}
                              onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  this.searchFieldValue("publisher", p);
                                }
                              }}>
                              {p}
                            </a>
                          );
                        }
                        return "";
                      });
                    } else {
                      outTemp.push(item.value);
                    }
                  } else {
                    if (fieldConfig.isUnEscape === "1") {
                      outTemp.push(ReactHtmlParser(item.value));
                    } else {
                      outTemp.push(item.value);
                    }
                  }
                  if (outTemp.length > 0) {
                    let fieldName = "";
                    if (item.key === "sr") {
                      fieldName = t("jumperrwd.common.sr");
                    } else if (item.key === "sourceName") {
                      fieldName = "來源資料庫";
                    } else if (item.key === "sourceEnName") {
                      fieldName = "Source Databases";
                    } else if (item.key === "reserveSort") {
                      fieldName = "預約排序";
                    } else {
                      fieldName = t(
                        "jumperrwd.eb_journal_extension.name." + item.key
                      );
                    }
                    return (
                      <li>
                        <div className="title">{fieldName}：</div>
                        <div>{outTemp}</div>
                      </li>
                    );
                  }
                }
                return "";
              })}
              {impactFactor && impactFactor !== "" && (
                <p>
                  <Link
                    href={
                      "/sendURLApiV3?type=jcr&issn=" +
                      standardno +
                      "&title=" +
                      encodeURIComponent(title)
                    }
                    target="_blank">
                    {impactFactor === "hidden" ? (
                      "JCR Impact Factor"
                    ) : (
                      <>
                        {t("jumper.common.impactfactor") + "=" + impactFactor}
                      </>
                    )}
                  </Link>
                </p>
              )}
              {cateList && (
                <>
                  <li>
                    <div className="title">{t("jumper.common.subject")}：</div>
                    <ul className="keyword">
                      {cateList.map((cateID) => {
                        return (
                          <>
                            <li>
                              <Link
                                href={
                                  "/resourceList?resourceType=ebook&pageType=subject&subject=" +
                                  cateID
                                }>
                                {t(
                                  "jumperrwd.ebookclass_dir.name." + cateID
                                )}
                              </Link>
                            </li>
                          </>
                        );
                      })}
                    </ul>
                  </li>
                </>
              )}
            </ul>
            {freeReadCode !== "" && <>{freeReadOut}</>}
            {returnCode !== "" && (
              <button
                className="btn_return"
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  this.journalAPIAct(
                    journalID,
                    "ebook",
                    "return",
                    returnCode,
                    "",
                    theSourceName
                  );
                }}>
                <a>{t("jumperrwd.ebBasicApi.return")}</a>
              </button>
            )}
            {cancelReserveCode !== "" && (
              <button
                className="btn_return"
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  this.journalAPIAct(
                    journalID,
                    "ebook",
                    "cancelReserve",
                    cancelReserveCode,
                    "",
                    theSourceName
                  );
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    this.journalAPIAct(
                      journalID,
                      "ebook",
                      "cancelReserve",
                      cancelReserveCode,
                      "",
                      theSourceName
                    );
                  }
                }}>
                <a>{t("jumperrwd.ebBasicApi.cancelReserve")}</a>
              </button>
            )}
            {borrowCode !== "" && (
              <button
                className="btn_borrow"
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  this.journalAPIAct(
                    journalID,
                    "ebook",
                    "borrow",
                    borrowCode,
                    "",
                    theSourceName
                  );
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    this.journalAPIAct(
                      journalID,
                      "ebook",
                      "borrow",
                      borrowCode,
                      "",
                      theSourceName
                    );
                  }
                }}>
                <a>{t("jumperrwd.ebBasicApi.borrow")}</a>
              </button>
            )}
          </div>
        </div>
        <EbookHistoryShareExportModal
          {...this.props}
          visible={this.state.exportModalVisible}
          ids={[tID]}
          listType={listType}
          close={() => this.setState({ exportModalVisible: false })}
        />
      </>
    );
  }
}

EbookHistoryDisplayComp.propTypes = {
  /**
   * The layout define display config is used to define layout.
   * The datasoure is from LayoutReturnResult.display.
   */
  displayConfig: PropTypes.array.isRequired,

  /** td content */
  data: PropTypes.array,

  /** layout define's func, record link or button behavior. */
  functionConfigList: PropTypes.array,

  /** layout define's func_right, record link or button behavior. */
  functionRightConfigList: PropTypes.array,

  /** layout define's left_right, record link or button behavior. */
  functionLeftConfigList: PropTypes.array,

  /** layout define's detail */
  detailList: PropTypes.object,

  /** button events */
  buttonEvent: PropTypes.func,

  /**
   * batch config
   */
  batchConfig: PropTypes.array,

  /**
   * serialNo
   */
  serialNo: PropTypes.number,

  /**
   * checked the row or not;
   */
  checked: PropTypes.bool,

  /**
   * row style className
   */
  rowStyle: PropTypes.string,
};

EbookHistoryDisplayComp.defaultProps = {
  buttonEvent: () => {},
  functionConfigList: [],
  functionRightConfigList: [],
  functionLeftConfigList: [],
  detailList: [],
  batchConfig: [],
  serialNo: -1,
  checked: false,
  rowStyle: "",
};

export default EbookHistoryDisplayComp;
