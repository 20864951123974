import client from "lib/ApolloClient";
import gql from "graphql-tag";

const custUsercateAdd = gql`
  mutation custUsercateAdd($form: UsercateAddForm) {
    mutation: custUsercateAdd(Input: $form) {
      success
    }
  }
`;

const custUsercateDel = gql`
  mutation custUsercateDel($form: UsercateDelForm) {
    mutation: custUsercateDel(Input: $form) {
      success
    }
  }
`;

const custUsercateEdit = gql`
  mutation custUsercateEdit($form: UsercateEditForm) {
    mutation: custUsercateEdit(Input: $form) {
      success
    }
  }
`;

const addCate = async (id, resourceType, cateName) => {
  let ids = [];
  if (id !== undefined && id !== null) ids.push(id);
  let result = await client.jumperrwdClient
    .mutate({
      mutation: custUsercateAdd,
      variables: {
        form: {
          ids: ids,
          cateName: cateName,
          resourceType: resourceType,
        },
      },
    })
    .then((data) => {
      return data;
    })
    .finally(() => {
      return null;
    });
  return result;
};

const addCateByIDList = async (ids, resourceType, cateName) => {
  let result = await client.jumperrwdClient
    .mutate({
      mutation: custUsercateAdd,
      variables: {
        form: {
          ids: ids,
          cateName: cateName,
          resourceType: resourceType,
        },
      },
    })
    .then((data) => {
      return data;
    })
    .finally(() => {
      return null;
    });
  return result;
};

const addListCate = async (ids, resourceType) => {
  let result = await client.jumperrwdClient
    .mutate({
      mutation: custUsercateAdd,
      variables: {
        form: {
          ids: ids,
          cateName: "",
          resourceType: resourceType,
        },
      },
    })
    .then((data) => {
      return data;
    })
    .finally(() => {
      return null;
    });
  return result;
};

const delCate = async (id, resourceType) => {
  let ids = [];
  ids.push(id);
  let result = await client.jumperrwdClient
    .mutate({
      mutation: custUsercateDel,
      variables: {
        form: {
          ids: ids,
          resourceType: resourceType,
        },
      },
    })
    .then((data) => {
      return data;
    })
    .finally(() => {
      return null;
    });
  return result;
};

const delListCate = async (ids, resourceType) => {
  let result = await client.jumperrwdClient
    .mutate({
      mutation: custUsercateDel,
      variables: {
        form: {
          ids: ids,
          resourceType: resourceType,
        },
      },
    })
    .then((data) => {
      return data;
    })
    .finally(() => {
      return null;
    });
  return result;
};

const addCateByCateID = async (id, resourceType, cateID) => {
  let ids = [];
  ids.push(id);
  let result = await client.jumperrwdClient
    .mutate({
      mutation: custUsercateAdd,
      variables: {
        form: {
          ids: ids,
          cateID: cateID,
          resourceType: resourceType,
        },
      },
    })
    .then((data) => {
      return data;
    })
    .finally(() => {
      return null;
    });
  return result;
};

const addListCateByCateID = async (ids, resourceType, cateID) => {
  let result = await client.jumperrwdClient
    .mutate({
      mutation: custUsercateAdd,
      variables: {
        form: {
          ids: ids,
          cateID: cateID,
          resourceType: resourceType,
        },
      },
    })
    .then((data) => {
      return data;
    })
    .finally(() => {
      return null;
    });
  return result;
};

const delCateByCateID = async (id, resourceType, cateID) => {
  let ids = [];
  if (id !== undefined && id !== null) ids.push(id);
  let result = await client.jumperrwdClient
    .mutate({
      mutation: custUsercateDel,
      variables: {
        form: {
          ids: ids,
          resourceType: resourceType,
          cateID: cateID,
        },
      },
    })
    .then((data) => {
      return data;
    })
    .finally(() => {
      return null;
    });
  return result;
};

const editCate = async (id, resourceType, name) => {
  let result = await client.jumperrwdClient
    .mutate({
      mutation: custUsercateEdit,
      variables: {
        form: {
          id: id,
          resourceType: resourceType,
          name: name,
        },
      },
    })
    .then((data) => {
      return data;
    })
    .finally(() => {
      return null;
    });
  return result;
};

export default {
  addCate,
  addCateByIDList,
  addListCate,
  delCate,
  delListCate,
  addCateByCateID,
  addListCateByCateID,
  delCateByCateID,
  editCate,
};
