import React from "react";
import PropTypes from "prop-types";
//import moment from 'moment';
import withLocation from "lib/withLocation";
import { arrangeData } from "components/list/action/action-util";
import { autobind } from "react-decoration";
import View from "components/list/view/View";
import rr from "lib/ResourceRelated";
import TeacherJournalFullTextItem from "components/teacher/TeacherJournalFullTextItem";
import ListCateComp from "components/resource/ListCateComp";
import Link from "lib/Link";
import ReactHtmlParser from "react-html-parser";
import JournalShareExportLightBox from "components/resource/JournalShareExportLightBox";

@withLocation
class TeacherShareReadingDisplayComp extends View {
  constructor(props) {
    super(props);
    this.state = {
      exportLightBoxVisible: false
    };
  }

  componentDidMount() {}

  @autobind
  getDisplayDataByField(display = [], fieldName = "") {
    let seqIndex = display
      .map(function (display) {
        return display.field;
      })
      .indexOf(fieldName);
    return display[seqIndex] || {};
  }

  render() {
    let {
      data,
      resourceData,
      rowStyle,
      serialNo,
      handleClick,
      i18n,
      t,
      info,
      variables,
      refetch,
      displayConfig,
    } = this.props;

    const arrangedData = arrangeData(data);

    const { journal_id, journal_title_ch } = arrangedData;

    let { language } = i18n;

    let { searchForm } = variables;
    let { resourceType = "ebook" } = searchForm;

    let { userCollectionList } = info || [];

    let journalURLList = resourceData.journalURLList;
    let journalAttachList = resourceData.journalAttachList;
    let cateList = resourceData.cateList;
    let standardno = resourceData.standardno;
    let impactFactor = resourceData.impactFactor;
    let pic = resourceData.pic || "";
    if (pic === "") {
      pic = "/file/images/icon_book.svg";
    }
    let srValue = resourceData.srValue;
    let assignedClassList = resourceData.assignedClassList;
    return (
      <>
        <div className={`list_block ${rowStyle}`}>
          <div className="list_num">
            <input
              type="checkbox"
              title={t("jumperrwd.common.selectJournal")}
              value={journal_id}
              checked={this.props.isChecked(journal_id)}
              onChange={(e) => {
                this.props.handleClick("addCheckedId", e.target);
              }}
            />
            {serialNo}
          </div>
          <div className="sort_icon">
            <div className="pic">
              <img src={pic} alt={t("jumperrwd.common.book")} />
            </div>
            <div className="sort_name"></div>
          </div>
          <div className="list_data">
            <h3>
              <a
                tabIndex="0"
                onClick={(e) => {
                  e.preventDefault();
                  handleClick("journal", {
                    id: journal_id,
                    resourceType: resourceType,
                  });
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleClick("journal", {
                      id: journal_id,
                      resourceType: resourceType,
                    });
                  }
                }}>
                {ReactHtmlParser(journal_title_ch)}
              </a>
            </h3>
            <ul>
              {data.map((item) => {
                let fieldConfig = this.getDisplayDataByField(
                  displayConfig,
                  item.key
                );
                if (
                  item.key !== "journal_id" &&
                  item.key !== "journal_title_ch" &&
                  item.key !== "journal_title_en"
                ) {
                  let outTemp = [];
                  if (item.key === "connrange") {
                    outTemp.push(t("jumperrwd.connrange.name." + item.value));
                  } else if (item.key === "publication_type") {
                    outTemp.push(
                      t("jumperrwd.publication_type.name." + item.value)
                    );
                  } else if (item.key === "notes") {
                    outTemp.push(ReactHtmlParser(item.value));
                  } else if (item.key === "eb_journal_url") {
                    let journalURLTemp = [];
                    if (journalURLList) {
                      journalURLList.map((journalURL, k) => {
                        if (k !== 0) {
                          journalURLTemp.push("、");
                        }
                        journalURLTemp.push(
                          <Link
                            href={journalURL.url}
                            target={journalURL.openWin === "0" ? "_blank" : ""}>
                            {journalURL.showName !== ""
                              ? journalURL.showName
                              : "(" + t("jumperrwd.common.noName") + ")"}
                          </Link>
                        );
                        return "";
                      });
                    }
                    if (journalURLTemp.length > 0) {
                      outTemp.push(journalURLTemp);
                    }
                  } else if (item.key === "ebattach") {
                    let journalAttachTemp = [];
                    if (journalAttachList) {
                      journalAttachList.map((journalAttach, k) => {
                        let showName = journalAttach.showName
                          ? journalAttach.showName
                          : t("jumperrwd.common.file");
                        let attachPath = journalAttach.attachPath
                          ? journalAttach.attachPath
                          : "";
                        let fileTypePhoto = rr.getFileTypePhoto(attachPath);
                        if (k !== 0) {
                          journalAttachTemp.push("、");
                        }
                        let ebattachTemp = [];
                        if (fileTypePhoto !== "") {
                          ebattachTemp.push(
                            <>
                              &nbsp;
                              <img
                                src={fileTypePhoto}
                                alt="檔案類型"
                                border="0"
                              />
                            </>
                          );
                        }
                        journalAttachTemp.push(
                          <Link href={"/file/downloadFile/" + attachPath}>
                            {showName}
                            {ebattachTemp}
                          </Link>
                        );
                        return "";
                      });
                    }
                    if (journalAttachTemp.length > 0) {
                      outTemp.push(journalAttachTemp);
                    }
                  } else if (
                    item.key === "ft_start_date" ||
                    item.key === "ft_end_date"
                  ) {
                    let timeValue = item.value;
                    if (timeValue.toLowerCase() === "current") {
                      timeValue = t("jumper.common.current");
                    }
                    outTemp.push(timeValue);
                  } else if (item.key === "sr") {
                    if (item.value !== "") {
                      item.value.split(",").map((sr, k) => {
                        let name = "jumperrwd.sr." + sr;
                        if (k !== 0) {
                          outTemp.push(", ");
                        }
                        outTemp.push(t(name));
                        return "";
                      });
                      outTemp.push(
                        " (" +
                          t("jumperrwd.common.srValue") +
                          ":" +
                          srValue +
                          ")"
                      );
                    }
                  } else {
                    if (fieldConfig.isUnEscape === "1") {
                      outTemp.push(ReactHtmlParser(item.value));
                    } else {
                      outTemp.push(item.value);
                    }
                  }
                  if (outTemp.length > 0) {
                    let fieldName = "";
                    if (item.key === "sr") {
                      fieldName = t("jumperrwd.common.sr");
                    } else {
                      fieldName = t(
                        "jumperrwd.eb_journal_extension.name." + item.key
                      );
                    }
                    return (
                      <li>
                        <div className="title">{fieldName}：</div>
                        <div>{outTemp}</div>
                      </li>
                    );
                  }
                }
                return "";
              })}
              {impactFactor && impactFactor !== "" && (
                <p>
                  <Link
                    href={
                      "/sendURLApiV3?type=jcr&issn=" +
                      standardno +
                      "&title=" +
                      encodeURIComponent(journal_title_ch)
                    }
                    target="_blank">
                    {impactFactor === "hidden" ? (
                      "JCR Impact Factor"
                    ) : (
                      <>
                        {t("jumper.common.impactfactor") + "=" + impactFactor}
                      </>
                    )}
                  </Link>
                </p>
              )}
              <TeacherJournalFullTextItem
                variables={variables}
                journalID={journal_id}
                language={language}
                {...this.props}
              />
              {cateList && (
                <>
                  <li>
                    <div className="title">{t("jumper.common.subject")}：</div>
                    <ul className="keyword">
                      {cateList.map((cateID) => {
                        let classType = "";
                        if (resourceType === "journal") {
                          classType = "eb_journal_cate";
                        } else if (resourceType === "paper_journal") {
                          classType = "eb_paperjournal_cate";
                        } else if (resourceType === "ebook") {
                          classType = "ebookclass_dir";
                        }
                        return (
                          <>
                            <li>
                              <Link
                                href={
                                  "/resourceList?resourceType=" +
                                  resourceType +
                                  "&pageType=subject&subject=" +
                                  cateID
                                }>
                                {t(
                                  "jumperrwd." + classType + ".name." + cateID
                                )}
                              </Link>
                            </li>
                          </>
                        );
                      })}
                    </ul>
                  </li>
                </>
              )}
              {assignedClassList && (
                <>
                  <li>
                    <div className="title">
                      {t("jumperrwd.common.assignedClass")}：
                    </div>
                    <ul class="keyword">
                      {assignedClassList.map((ac) => {
                        return (
                          <>
                            <li>
                              {t("jumperrwd.schoollist.name." + ac.schoolID) +
                                " " +
                                ac.classTitle}
                            </li>
                          </>
                        );
                      })}
                    </ul>
                  </li>
                </>
              )}
            </ul>
          </div>
          <div className="function_btn">
            <button
              type="button"
              tabIndex="0"
              title={t("jumperrwd.common.shareAndExport")}
              onClick={() => this.setState({ exportLightBoxVisible: true })}>
              <i className="i_share"></i>
              <span>{t("jumperrwd.common.shareAndExport")}</span>
            </button>
            <ListCateComp
              {...this.props}
              id={journal_id}
              userCollectionList={userCollectionList}
              resourceType={resourceType}
              refetch={refetch}
              isButton={true}
            />
          </div>
        </div>
        <JournalShareExportLightBox
          visible={this.state.exportLightBoxVisible}
          ids={[journal_id]}
          resourceType={searchForm.resourceType}
          close={() => this.setState({ exportLightBoxVisible: false })}
          {...this.props}
        />
      </>
    );
  }
}

TeacherShareReadingDisplayComp.propTypes = {
  /**
   * The layout define display config is used to define layout.
   * The datasoure is from LayoutReturnResult.display.
   */
  displayConfig: PropTypes.array.isRequired,

  /** td content */
  data: PropTypes.array,

  /** layout define's func, record link or button behavior. */
  functionConfigList: PropTypes.array,

  /** layout define's func_right, record link or button behavior. */
  functionRightConfigList: PropTypes.array,

  /** layout define's left_right, record link or button behavior. */
  functionLeftConfigList: PropTypes.array,

  /** layout define's detail */
  detailList: PropTypes.object,

  /** button events */
  buttonEvent: PropTypes.func,

  /**
   * batch config
   */
  batchConfig: PropTypes.array,

  /**
   * serialNo
   */
  serialNo: PropTypes.number,

  /**
   * checked the row or not;
   */
  checked: PropTypes.bool,

  /**
   * row style className
   */
  rowStyle: PropTypes.string,
};

TeacherShareReadingDisplayComp.defaultProps = {
  buttonEvent: () => {},
  functionConfigList: [],
  functionRightConfigList: [],
  functionLeftConfigList: [],
  detailList: [],
  batchConfig: [],
  serialNo: -1,
  checked: false,
  rowStyle: "",
};

export default TeacherShareReadingDisplayComp;
