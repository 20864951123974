import React from "react";
import { inject, observer } from "mobx-react";
import gql from "graphql-tag";
import client from "lib/ApolloClient";
import { ApolloProvider, Query } from "react-apollo";
import ReactLoading from "react-loading";
import { autobind } from "react-decoration";
import EbookNoticeEditEmailLightBox from "components/cust/EbookNoticeEditEmailLightBox";

const getEbookNoticeEmail = gql`
  query getEbookNoticeEmail {
    getEbookNoticeEmail {
      email
    }
  }
`;

@inject("appStore")
@observer
class EbookNoticeMyEmailComp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      auth: props.readerStore.auth,
      email: "",
      refetch: null
    };
  }

  componentDidMount() {}

  UNSAFE_componentWillReceiveProps(props) {}

  @autobind
  openEdit(e) {
    e.preventDefault();
    this.editEmailLightBox.popup();
  }

  render() {
    return (
      <>
        <div className="list_all">
          <div className="detail_block">
            <div className="info">
              <ApolloProvider client={client.jumperrwdClient}>
                <Query query={getEbookNoticeEmail} fetchPolicy="network-only">
                  {({ loading, error, data, refetch }) => {
                    if (loading)
                      return (
                        <div style={{ textAlign: "center" }}>
                          <ReactLoading
                            type="cylon"
                            height={"10%"}
                            width={"20%"}
                            color="#005D98"
                          />
                        </div>
                      );
                    if (error) return `Error!${error.message}`;
                    if (this.state.refetch === null) {
                      this.setState({ refetch: refetch });
                    }

                    if (data.getEbookNoticeEmail) {
                      let { email } = data.getEbookNoticeEmail;
                      return (
                        <form>
                          {email !== "" && (
                            <>
                              <label htmlFor="username">
                                您所設定收取通知的電子信箱：
                              </label>
                              <br />
                              {email}
                            </>
                          )}
                          {email === "" && (
                            <>
                              <label htmlFor="username">
                                尚未修改設定email，請修改email：
                              </label>
                            </>
                          )}
                          <button type="submit" onClick={this.openEdit}>修改</button>
                        </form>
                      );
                    }
                    return "";
                  }}
                </Query>
              </ApolloProvider>
            </div>
          </div>
        </div>
        <EbookNoticeEditEmailLightBox
          ref={(c) => {
            this.editEmailLightBox = c;
          }}
          {...this.props}
          lightboxClass="personal_lightbox"
          blockClass="personalblock"
          refetch={this.state.refetch}
        />
      </>
    );
  }
}

export default EbookNoticeMyEmailComp;
