import React from "react";
import { inject, observer } from "mobx-react";
import { autobind } from "react-decoration";
import navigate from "lib/navigate";
import qs from "query-string";
import ReactHtmlParser from "react-html-parser";
import EbookNoticeMyNoticeComp from "components/cust/EbookNoticeMyNoticeComp";
import EbookNoticeMyEmailComp from "components/cust/EbookNoticeMyEmailComp";

@inject("appStore")
@observer
class EbookNoticeComp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      auth: props.readerStore.auth,
    };
  }

  componentDidMount() {}

  UNSAFE_componentWillReceiveProps(props) {}

  @autobind
  changeActionType(actionType) {
    let { location } = this.props;
    let params = {
      pageType: "ebookNotice",
      actionType: actionType,
    };
    navigate([location.pathname, qs.stringify(params)].join("?"));
  }

  render() {
    let { searchForm, t } = this.props;
    let actionType = searchForm.actionType || "myNotice";

    return (
      <>
        <div className="filter_search">
          <div className="close" tabindex="0"></div>
          <div className="sort_list">
            <div className="list_content">
              {ReactHtmlParser(t("jumperrwd.ebookNotice.queryGroupNote"))}
            </div>
          </div>
        </div>
        <div className="search_result">
          <h2>通知設定</h2>
          <div className="tabSet">
            <ul className="tabs">
              <li className={actionType === "myNotice" ? "active" : ""}>
                <a
                  href="javascript:;"
                  onClick={(e) => {
                    e.preventDefault();
                    this.changeActionType("myNotice");
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      this.changeActionType("myNotice");
                    }
                  }}>
                  我的通知
                </a>
              </li>
              <li className={actionType === "myEmail" ? "active" : ""}>
                <a
                  href="javascript:;"
                  onClick={(e) => {
                    e.preventDefault();
                    this.changeActionType("myEmail");
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      this.changeActionType("myEmail");
                    }
                  }}>
                  我的電子信箱
                </a>
              </li>
            </ul>
          </div>
          {actionType === "myNotice" && (
            <EbookNoticeMyNoticeComp {...this.props} />
          )}
          {actionType === "myEmail" && (
            <EbookNoticeMyEmailComp {...this.props} />
          )}
        </div>
      </>
    );
  }
}

export default EbookNoticeComp;
