import React from "react";
import gql from "graphql-tag";
import { withI18next } from "lib/withI18next";
import client from "lib/ApolloClient";
import { autobind } from "react-decoration";

const journalListOutput = gql`
  query journalListOutput($form: ResourceOutputForm) {
    journalListOutput(Input: $form) {
      success
      contentType
      fileName
      content
    }
  }
`;

const getJournalListHTML = gql`
  query getJournalListHTML($form: ResourceOutputForm) {
    getJournalListHTML(Input: $form)
  }
`;

const shareJournalList = gql`
  mutation shareJournalList($form: ResourceOutputForm, $email: String) {
    shareJournalList(Input: $form, email: $email) {
      success
    }
  }
`;

@withI18next(["common"])
class JournalShareExportLightBox extends React.Component {
  constructor(props) {
    super(props);

    let exportOption = props.appStore.globalConfig["jumperrwd.journal.exportOption"];

    this.state = {
      display: props.visible ? "block" : "none",
      fileName: "",
      exportHref: "",
      email: "",
      exportOption,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.visible !== prevState.visible) {
      return {
        display: nextProps.visible ? "block" : "none",
      };
    }
    return null;
  }

  getExportData = (outType) => {
    if (this.props.ids.length === 0) {
      alert(this.props.t("jumperrwd.export.pleaseSelectOne"));
      return;
    }

    client.jumperrwdClient
      .query({
        query: journalListOutput,
        variables: {
          form: {
            ids: this.props.ids,
            resourceType: this.props.resourceType,
            outType: outType,
          },
        },
      })
      .then((res) => {
        if (res.data.journalListOutput && res.data.journalListOutput.success) {
          this.setState({
            fileName: res.data.journalListOutput.fileName,
            exportHref: `data:${res.data.journalListOutput.contentType};base64,${res.data.journalListOutput.content}`,
          });
          this.downloadLink.click();
        } else {
          alert(this.props.t("jumperrwd.common.exportFailed"));
        }
      });
  };

  componentDidMount() {}

  @autobind
  print(e) {
    e.preventDefault();
    let { t } = this.props;
    if (this.props.ids.length === 0) {
      alert(t("jumperrwd.export.pleaseSelectOne"));
      return;
    }

    client.jumperrwdClient
      .query({
        query: getJournalListHTML,
        variables: {
          form: {
            ids: this.props.ids,
            resourceType: this.props.resourceType,
          },
        },
      })
      .then((res) => {
        var p = window.open("about:blank");
        setTimeout(() => {
          p.document.write(res.data.getJournalListHTML);

          setTimeout(() => {
            p.print();
            p.close();
          }, 500);
        }, 500);
      });
  }

  render() {
    let { t } = this.props;
    return (
      <div
        className="personal_lightbox"
        style={{ display: this.state.display }}>
        <div className="overlay" onClick={this.props.close}></div>
        <div className="personalblock">
          <div
            className="close"
            tabIndex="0"
            onClick={this.props.close}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                this.props.close(e);
              }
            }}></div>
          <h3>{t("jumperrwd.common.shareAndExport")}</h3>
          <div className="export">
            <h4>{t("jumperrwd.common.export")}</h4>
            <ul>
              {this.state.exportOption &&
                this.state.exportOption.split(";").map((option) => {
                  switch (option) {
                    case "excel":
                      return (
                        <li>
                          <a
                            tabIndex="0"
                            title={t("jumperrwd.common.exportExcel")}
                            onClick={() => this.getExportData("1")}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                this.getExportData("1");
                              }
                            }}>
                            EXCEL
                          </a>
                        </li>
                      );
                    case "marc":
                      return (
                        <li>
                          <a
                            tabIndex="0"
                            title={t("jumperrwd.common.exportMARC")}
                            onClick={() => this.getExportData("2")}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                this.getExportData("2");
                              }
                            }}>
                            MARC
                          </a>
                        </li>
                      );
                    case "iso2709":
                      return (
                        <li>
                          <a
                            tabIndex="0"
                            title={t("jumperrwd.common.exportISO2709")}
                            onClick={() => this.getExportData("3")}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                this.getExportData("3");
                              }
                            }}>
                            ISO 2709
                          </a>
                        </li>
                      );
                    case "refwork":
                      return (
                        <li>
                          <a
                            tabIndex="0"
                            title={t("jumperrwd.common.exportRefWork")}
                            onClick={() => this.getExportData("4")}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                this.getExportData("4");
                              }
                            }}>
                            RefWork
                          </a>
                        </li>
                      );
                    case "endnote":
                      return (
                        <li>
                          <a
                            tabIndex="0"
                            title={t("jumperrwd.common.exportEndNote")}
                            onClick={() => this.getExportData("5")}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                this.getExportData("5");
                              }
                            }}>
                            EndNote
                          </a>
                        </li>
                      );
                    case "refmanager":
                      return (
                        <li>
                          <a
                            tabIndex="0"
                            title={t("jumperrwd.common.exportReference")}
                            onClick={() => this.getExportData("6")}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                this.getExportData("6");
                              }
                            }}>
                            Reference Manager
                          </a>
                        </li>
                      );
                    case "TXT":
                      return (
                        <li>
                          <a
                            tabIndex="0"
                            title={t("jumperrwd.common.exportTxt")}
                            onClick={() => this.getExportData("7")}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                this.getExportData("7");
                              }
                            }}>
                            TXT
                          </a>
                        </li>
                      );
                    case "print":
                      return (
                        <li>
                          <a
                            tabIndex="0"
                            title={t("jumperrwd.common.print")}
                            onClick={this.print.bind(this)}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                this.print(e);
                              }
                            }}>
                            {t("jumperrwd.common.print")}
                          </a>
                        </li>
                      );
                    default:
                      return <></>;
                  }
                })}
            </ul>
          </div>
          <hr />
          <div className="share">
            <h4>{t("jumperrwd.common.share")}</h4>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                if (!this.state.email || this.state.email === "") {
                  alert(t("jumperrwd.email.cannotEmpty"));
                } else {
                  client.jumperrwdClient
                    .mutate({
                      mutation: shareJournalList,
                      variables: {
                        form: {
                          ids: this.props.ids,
                          resourceType: this.props.resourceType,
                        },
                        email: this.state.email,
                      },
                    })
                    .then((res) => {
                      if (res.data.shareJournalList.success) {
                        alert(t("jumperrwd.email.sendSuccess"));
                      } else {
                        alert(t("jumperrwd.email.sendFailed"));
                      }
                    });
                }
              }}>
              <label htmlFor="username">{t("jumperrwd.share.mailTitle")}</label>
              <input
                type="email"
                id="username"
                placeholder={t("jumperrwd.common.enterEmail")}
                value={this.state.email}
                onChange={(e) => {
                  this.setState({ email: e.target.value });
                }}
              />
              <div className="btn_grp_right">
                <button type="submit">{t("jumperrwd.email.send")}</button>
              </div>
            </form>
          </div>
        </div>
        <a
          style={{ display: "none" }}
          ref={(c) => {
            this.downloadLink = c;
          }}
          href={this.state.exportHref}
          download={this.state.fileName}>
          downloadLink
        </a>
      </div>
    );
  }
}

export default JournalShareExportLightBox;
