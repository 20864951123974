import React from "react";
import Layout from "components/layout";
import { withI18next } from "lib/withI18next";
import BookmarkListComp from "components/cust/BookmarkListComp";
import CustListTab from "components/cust/CustListTab";
import SearchBlock from "components/search/SearchBlock";
import { autobind } from "react-decoration";
import { page } from "components/page";
import navigate from "lib/navigate";
import qs from "query-string";
import ResourceListComp from "components/cust/ResourceListComp";
import SearchHistoryComp from "components/cust/SearchHistoryComp";
import SdiListComp from "components/cust/SdiListComp";
import LibHistoryListComp from "components/cust/LibHistoryListComp";
import EbookHistoryListComp from "components/cust/EbookHistoryListComp";
import EbookNoticeComp from "components/cust/EbookNoticeComp";
import MyCommendListComp from "components/cust/MyCommendListComp";
import MyGradeListComp from "components/cust/MyGradeListComp";

@withI18next(["common"], true)
@page
class CustList extends React.Component {
  constructor(props) {
    super(props);
    let state = this.getQueryState(props);
    this.state = state;
  }

  UNSAFE_componentWillReceiveProps(props) {
    if (this.props !== props) {
      if (this.state.auth !== props.readerStore.auth) {
        this.resetPage();
      }
      this.setQueryState(props);
    }
  }

  @autobind
  setQueryState(props) {
    let state = this.getQueryState(props);

    if (JSON.stringify(state) !== JSON.stringify(this.state)) {
      this.setState({
        ...state,
      });
    }
  }

  @autobind
  getQueryState(props) {
    let { search, readerStore, appStore } = props;
    let params = { ...search };
    let { globalConfig, resourceConfig } = appStore;
    let {
      resourceType,
      pageNo,
      limit,
      searchInput,
      searchField,
      op,
      showType,
      filterField,
      filterValue,
      sort,
      order,
      pageType,
      subject,
      college,
      unit,
      classSn,
      par,
      phonetic,
      strokes,
      language,
      alphabet,
      dbID,
      custKey,
      cate_id,
      api_id,
      listType,
      actionType,
    } = params;
    let publicationType = "";

    let tabList = [
      "bookmark",
      "resources",
      "history",
      "sdi",
      "libHistory",
      "ebookHistory",
      "ebookNotice",
      "myCommend",
      "myGrade",
    ];
    let tabListString = globalConfig["jumperrwd.cust.tabList"] || "";
    if (tabListString !== "") {
      tabList = JSON.parse(tabListString);
    }
    if (globalConfig["jumper.common.isMyCollection"] === "0") {
      tabList = tabList.filter((tab) => tab !== "bookmark");
    }
    if (globalConfig["jumper.common.isresource"] !== "1") {
      tabList = tabList.filter((tab) => tab !== "resources");
    }
    if (globalConfig["jumper.common.search.isSdi"] !== "1") {
      tabList = tabList.filter((tab) => tab !== "sdi");
    }
    if (globalConfig["jumper.common.islibhistory"] !== "1") {
      tabList = tabList.filter((tab) => tab !== "libHistory");
    }
    if (globalConfig["jumperrwd.common.isEbookHistory"] !== "1") {
      tabList = tabList.filter((tab) => tab !== "ebookHistory");
    }
    if (globalConfig["jumperrwd.common.isEbookNotice"] !== "1") {
      tabList = tabList.filter((tab) => tab !== "ebookNotice");
    }
    if (globalConfig["jumperrwd.common.isMyCommend"] !== "1") {
      tabList = tabList.filter((tab) => tab !== "myCommend");
    }
    if (globalConfig["jumperrwd.common.isMyGrade"] !== "1") {
      tabList = tabList.filter((tab) => tab !== "myGrade");
    }

    let resourceTypeSet = [
      "databases",
      "network",
      "journal",
      "ejournal",
      "ebook",
      "paper_journal",
    ];

    let defaultResourceType =
      resourceConfig && resourceConfig.resourceTabList
        ? resourceConfig.resourceTabList[0]
        : "databases";

    if (pageType === undefined || pageType === "") {
      pageType = tabList[0];
    }

    if (pageType === "bookmark") {
      if (resourceTypeSet.indexOf(resourceType) === -1) {
        resourceType = defaultResourceType;
      }

      if (sort === undefined || sort === "") {
        sort = "cretime";
      }
    }

    if (pageType === "history") {
      if (resourceType === undefined || resourceType === "") {
        resourceType = "3days";
      }
    }

    pageNo = pageNo || 1;
    limit = limit || 10;
    searchInput = [].concat(searchInput || []);
    searchField = [].concat(searchField || []);
    op = [].concat(op || []);
    showType = showType || "simple";
    filterField = [].concat(filterField || []);
    filterValue = [].concat(filterValue || []);

    op.splice(0, 0, "");
    let removeId = 0;
    searchInput = searchInput.filter((v, k) => {
      if (v !== null && v !== undefined && v.trim() !== "") {
        return 1;
      } else {
        searchField.splice(k - removeId, 1);
        op.splice(k - removeId, 1);
        removeId++;
      }
      return "";
    });
    op.splice(0, 1);

    if (resourceType === "journal") {
      publicationType = "1";
    } else if (resourceType === "paper_journal") {
      publicationType = "3";
    } else if (resourceType === "ebook") {
      publicationType = "2";
    }

    if (sort === "hot") {
      order = "desc";
    }

    let searchForm = {
      publicationType,
      pageNo,
      limit,
      searchField,
      searchInput: searchInput.map(
        Function.prototype.call,
        String.prototype.trim
      ),
      op,
      showType,
      filterField,
      filterValue,
      sort,
      order,
      subject,
      college,
      unit,
      resourceType,
      pageType,
      classSn,
      par,
      phonetic,
      strokes,
      language,
      alphabet,
      dbID,
      custKey,
      cate_id,
      api_id,
      listType,
      actionType,
    };

    Object.keys(searchForm).map((key) => {
      if (
        searchForm[key] === null ||
        (typeof searchForm[key] === "string" && searchForm[key].trim() === "")
      ) {
        delete searchForm[key];
      }
      return "";
    });

    let state = {
      searchForm: searchForm,
      auth: readerStore.auth,
      resourceType: resourceType,
      pageType: pageType,
      tabList: tabList,
    };

    return state;
  }

  @autobind
  resetPage() {
    let { search, location } = this.props;
    let params = { ...search };
    delete params["pageNo"];
    delete params["limit"];
    let pathname =
      qs.stringify(params) === ""
        ? location.pathname
        : [location.pathname, qs.stringify(params)].join("?");
    navigate(pathname);
  }

  @autobind
  custListComp() {
    if (this.state.searchForm !== undefined) {
      switch (this.state.pageType) {
        case "bookmark":
          return (
            <BookmarkListComp
              searchForm={this.state.searchForm}
              pageType={this.state.pageType}
              {...this.props}
            />
          );
        case "resources":
          return (
            <ResourceListComp
              searchForm={this.state.searchForm}
              pageType={this.state.pageType}
              {...this.props}
            />
          );
        case "history":
          return (
            <SearchHistoryComp
              searchForm={this.state.searchForm}
              pageType={this.state.pageType}
              {...this.props}
            />
          );
        case "sdi":
          return (
            <SdiListComp
              searchForm={this.state.searchForm}
              pageType={this.state.pageType}
              {...this.props}
            />
          );
        case "libHistory":
          return (
            <LibHistoryListComp
              searchForm={this.state.searchForm}
              pageType={this.state.pageType}
              {...this.props}
            />
          );
        case "ebookHistory":
          return (
            <EbookHistoryListComp
              searchForm={this.state.searchForm}
              pageType={this.state.pageType}
              {...this.props}
            />
          );
        case "ebookNotice":
          return (
            <EbookNoticeComp
              searchForm={this.state.searchForm}
              pageType={this.state.pageType}
              {...this.props}
            />
          );
        case "myCommend":
          return (
            <MyCommendListComp
              searchForm={this.state.searchForm}
              pageType={this.state.pageType}
              {...this.props}
            />
          );
        case "myGrade":
          return (
            <MyGradeListComp
              searchForm={this.state.searchForm}
              pageType={this.state.pageType}
              {...this.props}
            />
          );
        default:
      }
    }
  }

  render() {
    let custInfo = "";
    if (
      "jumperrwd.custInfo." + this.state.pageType !==
      this.props.t("jumperrwd.custInfo." + this.state.pageType)
    ) {
      custInfo = this.props.t("jumperrwd.custInfo." + this.state.pageType);
    }
    return (
      <Layout {...this.props}>
        <div className="main personal" id="center">
          <SearchBlock />
          <CustListTab
            {...this.props}
            searchForm={this.state.searchForm}
            tabList={this.state.tabList}
          />
          <section className="main_content">
            {custInfo !== "" && <p className="personal_info">{custInfo}</p>}
            <div className="container">
              {this.props.readerStore.auth && this.custListComp()}
            </div>
          </section>
        </div>
      </Layout>
    );
  }
}

export default CustList;
