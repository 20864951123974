import React from "react";
import client from "lib/ApolloClient";
import { ApolloProvider } from "react-apollo";
import gql from "graphql-tag";
import { inject, observer } from "mobx-react";
import { autobind } from "react-decoration";
import navigate from "lib/navigate";
import qs from "query-string";
import DataList from "components/list/DataList";
import GraphqlFragment from "lib/GraphqlFragment";
import StudentShareReadingQueryGroupComp from "components/student/StudentShareReadingQueryGroupComp";
import StudentShareReadingDisplayComp from "components/student/StudentShareReadingDisplayComp";

const getStudentShareReadingList = gql`
  query getStudentShareReadingList($searchForm: SearchForm) {
    getStudentShareReadingList(Input: $searchForm) {
      display {
        field
        name
        position
        type
        isUnEscape
      }
      sort {
        name
        order
        field
        default
      }
      batch {
        ...getElemtn
      }
      list {
        values {
          ref {
            key
            value
          }
          resourceItem {
            journalAttachList {
              showName
              attachPath
            }
            journalURLList {
              url
              openWin
              showName
            }
            cateList
            standardno
            impactFactor
            pic
            srValue
            assignedTeacherList {
              teacherName
            }
          }
        }
      }
      options {
        max
        pagesize
        showpage
      }
      info {
        total
        count
        limit
        pageNo
        totalPage
        start
        hyftdToken
        userCollectionList
        sortFieldList {
          type
          order
          message
        }
        pages {
          val
          active
        }
        showNext
        showPrevious
        nFilterTotal
        schoolID
        classTitle
      }
    }
  }
  ${GraphqlFragment.defaultLayoutFragment}
`;

@inject("appStore")
@observer
class StudentShareReadingComp extends React.Component {
  constructor(props) {
    super(props);
    let { searchForm } = props;
    let listType = searchForm.listType || "allShareReading";
    this.state = {
      auth: props.readerStore.auth,
      refetch: null,
      showFilter: false,
      listType: listType,
      checkedData: [],
      schoolID: "",
      classTitle: "",
    };
  }

  componentDidMount() {}

  UNSAFE_componentWillReceiveProps(props) {
    if (props.searchForm.listType !== this.state.listType) {
      let listType = props.searchForm.listType || "allShareReading";
      this.setState({
        showFilter: false,
        listType: listType,
        checkedData: [],
      });
    }
  }

  @autobind
  onQueryCompleted(data) {
    let { info } = data.getStudentShareReadingList;
    let { schoolID = "", classTitle = "" } = info;
    if (
      schoolID !== this.state.schoolID ||
      classTitle !== this.state.classTitle
    ) {
      this.setState({
        schoolID: schoolID,
        classTitle: classTitle,
      });
    }
  }

  @autobind
  changeListType(listType) {
    let { location } = this.props;
    let params = {
      pageType: "studentShareReading",
      listType: listType,
    };
    navigate([location.pathname, qs.stringify(params)].join("?"));
  }

  @autobind
  getRefetch(refetch) {
    this.setState({ refetch: refetch });
  }

  @autobind
  handleClick(type, item) {
    if (type === "addCheckedId") {
      var c = this.state.checkedData;
      if (item.checked) {
        c.push(item.value);
      } else {
        var index = c.indexOf(item.value);
        if (index !== -1) {
          c.splice(index, 1);
        }
      }

      this.setState({ checkedData: c });
    }
  }

  @autobind
  changeShowFilter(type) {
    if (type) {
      this.setState({ showFilter: true });
      document.body.classList.add("fix");
    } else {
      this.setState({ showFilter: false });
      document.body.classList.remove("fix");
    }
  }

  render() {
    let { searchForm, t } = this.props;
    let { showFilter, schoolID, classTitle } = this.state;
    let listType = searchForm.listType || "shareReading";
    searchForm.listType = listType;
    let schoolClass = "";
    if (schoolID !== "") {
      schoolClass = schoolClass + t("jumperrwd.schoollist.name." + schoolID);
    }
    if (classTitle !== "") {
      schoolClass = schoolClass + " " + classTitle
    }
    return (
      <ApolloProvider client={client.jumperrwdClient}>
        <div className={showFilter ? "filter_search show_up" : "filter_search"}>
          <a
            className="accesskey"
            href="javascript:;"
            id="aL"
            accessKey="L"
            title={t("jumperrwd.common.leftSideArea")}>
            :::
          </a>
          <StudentShareReadingQueryGroupComp
            ref={(c) => (this.queryGroup = c)}
            {...this.props}
            changeShowFilter={this.changeShowFilter}
            refetch={this.state.refetch}
            showFilter={showFilter}
          />
        </div>
        <div className="search_result">
          <h2>
            {"共讀書單 - " + schoolClass}
          </h2>
          <div className="tabSet">
            <ul className="tabs">
              <li className={listType === "shareReading" ? "active" : ""}>
                <a
                  href="javascript:;"
                  onClick={(e) => {
                    e.preventDefault();
                    this.changeListType("shareReading");
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      this.changeListType("shareReading");
                    }
                  }}>
                  共讀書單
                </a>
              </li>
            </ul>
          </div>
          <div className="list_all">
            <DataList
              query={getStudentShareReadingList}
              variables={{
                searchForm: this.props.searchForm,
              }}
              fetchPolicy={"network-only"}
              displayComp={StudentShareReadingDisplayComp}
              pagination={"FixedPagination"}
              getRefetch={this.getRefetch}
              handleClick={this.handleClick}
              onQueryCompleted={this.onQueryCompleted}
              showNoData={false}
              hasFilter={true}
              isChecked={(id) => {
                if (this.state.checkedData.includes(id)) return true;
                else return false;
              }}
            />
          </div>
        </div>
      </ApolloProvider>
    );
  }
}

export default StudentShareReadingComp;
