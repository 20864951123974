import { autobind } from "react-decoration";
import LightBox from "components/common/LightBox";
import React from "react";
import navigate from "lib/navigate";
import axios from "axios";
import ReactHtmlParser from "react-html-parser";

@LightBox
class StudentEnterLightBox extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  UNSAFE_componentWillReceiveProps(props) {}

  @autobind
  toHome(e) {
    e.preventDefault();
    navigate("/");
  }

  @autobind
  sendAgree(e) {
    e.preventDefault();
    this.props.appStore.setAgreeStudentArea(1);
    axios
      .get("/api/jumperrwdWs/setSession?key=agreeStudentArea&value=1")
      .then((res) => {
        this.props.readerStore.syncSessionCookie().then(() => {
          if (this.props.close) {
            this.props.close();
          }
        });
      });
  }

  beforeClose = () => {
    let { readerStore, appStore } = this.props;
    let { agreeStudentArea } = appStore;
    let { session } = readerStore;
    if (session.agreeStudentArea !== "1" && agreeStudentArea !== 1) {
      navigate("/");
    }
    return true;
  };

  render() {
    let { t } = this.props;
    return (
      <>
        <h3>您即將進入共讀書單功能</h3>
        <p>
          {ReactHtmlParser(t("jumperrwd.student.enterLightBoxMainMessage"))}
        </p>
        <div className="scroll_content">
          <div className="table_list">
            <pre>
              {ReactHtmlParser(t("jumperrwd.student.enterLightBoxInternalMessage"))}
            </pre>
          </div>
        </div>
        <div className="btn_grp">
          <button className="btn btn-del" onClick={this.toHome}>
            不同意
          </button>
          <button className="btn btn-submit" onClick={this.sendAgree}>
            同意
          </button>
        </div>
      </>
    );
  }
}

export default StudentEnterLightBox;
