import React from "react";
import Layout from "components/layout";
import { withI18next } from "lib/withI18next";
import TeacherAreaTab from "components/teacher/TeacherAreaTab";
import SearchBlock from "components/search/SearchBlock";
import { autobind } from "react-decoration";
import { page } from "components/page";
import navigate from "lib/navigate";
import qs from "query-string";
import TeacherBindingComp from "components/teacher/TeacherBindingComp";
import TeacherShareReadingComp from "components/teacher/TeacherShareReadingComp";
import TeacherEnterLightBox from "components/teacher/TeacherEnterLightBox";
import TeacherClassReadingComp from "components/teacher/TeacherClassReadingComp";
import TeacherRecommendEbookComp from "components/teacher/TeacherRecommendEbookComp";

@withI18next(["common"], true)
@page
class TeacherArea extends React.Component {
  constructor(props) {
    super(props);
    let state = this.getQueryState(props);
    this.state = state;
  }

  componentDidMount() {
    let { readerStore, appStore } = this.props;
    let { agreeTeacherArea } = appStore;
    let { session } = readerStore;
    if (
      session.agreeTeacherArea !== "1" &&
      agreeTeacherArea !== 1 &&
      this.teacherEnterLightBox
    ) {
      this.teacherEnterLightBox.popup();
    }
  }

  UNSAFE_componentWillReceiveProps(props) {
    let { readerStore, appStore } = props;
    let { agreeTeacherArea } = appStore;
    let { session } = readerStore;
    if (this.props !== props) {
      if (this.state.auth !== props.readerStore.auth) {
        this.resetPage();
      }
      this.setQueryState(props);
    }
    if (
      session.agreeTeacherArea !== "1" &&
      agreeTeacherArea !== 1 &&
      this.teacherEnterLightBox &&
      this.teacherEnterLightBox.display === "none"
    ) {
      this.teacherEnterLightBox.popup();
    }
  }

  @autobind
  setQueryState(props) {
    let state = this.getQueryState(props);

    if (JSON.stringify(state) !== JSON.stringify(this.state)) {
      this.setState({
        ...state,
      });
    }
  }

  @autobind
  getQueryState(props) {
    let { search, readerStore } = props;
    let params = { ...search };
    let {
      id,
      resourceType,
      pageNo,
      limit,
      searchInput,
      searchField,
      op,
      showType,
      filterField,
      filterValue,
      sort,
      order,
      pageType,
      subject,
      college,
      unit,
      classSn,
      par,
      phonetic,
      strokes,
      language,
      alphabet,
      dbID,
      custKey,
      cate_id,
      api_id,
      listType,
      actionType,
      bindingSn,
    } = params;
    let publicationType = "";

    let tabList = [
      "teacherBinding",
      "teacherShareReading",
      "teacherClassReading",
      "teacherRecommendEbook",
    ];

    if (pageType === undefined || pageType === "") {
      pageType = tabList[0];
    }

    if (resourceType === undefined || resourceType === "") {
      resourceType = "ebook";
    }

    pageNo = pageNo || 1;
    limit = limit || 10;
    searchInput = [].concat(searchInput || []);
    searchField = [].concat(searchField || []);
    op = [].concat(op || []);
    showType = showType || "simple";
    filterField = [].concat(filterField || []);
    filterValue = [].concat(filterValue || []);

    op.splice(0, 0, "");
    let removeId = 0;
    searchInput = searchInput.filter((v, k) => {
      if (v !== null && v !== undefined && v.trim() !== "") {
        return 1;
      } else {
        searchField.splice(k - removeId, 1);
        op.splice(k - removeId, 1);
        removeId++;
      }
      return "";
    });
    op.splice(0, 1);

    if (resourceType === "journal") {
      publicationType = "1";
    } else if (resourceType === "paper_journal") {
      publicationType = "3";
    } else if (resourceType === "ebook") {
      publicationType = "2";
    }

    if (sort === "hot") {
      order = "desc";
    }

    let searchForm = {
      id,
      publicationType,
      pageNo,
      limit,
      searchField,
      searchInput: searchInput.map(
        Function.prototype.call,
        String.prototype.trim
      ),
      op,
      showType,
      filterField,
      filterValue,
      sort,
      order,
      subject,
      college,
      unit,
      resourceType,
      pageType,
      classSn,
      par,
      phonetic,
      strokes,
      language,
      alphabet,
      dbID,
      custKey,
      cate_id,
      api_id,
      listType,
      actionType,
      bindingSn,
    };

    Object.keys(searchForm).map((key) => {
      if (
        searchForm[key] === null ||
        (typeof searchForm[key] === "string" && searchForm[key].trim() === "")
      ) {
        delete searchForm[key];
      }
      return "";
    });

    let state = {
      searchForm: searchForm,
      auth: readerStore.auth,
      resourceType: resourceType,
      pageType: pageType,
      tabList: tabList,
    };

    return state;
  }

  @autobind
  resetPage() {
    let { search, location } = this.props;
    let params = { ...search };
    delete params["pageNo"];
    delete params["limit"];
    let pathname =
      qs.stringify(params) === ""
        ? location.pathname
        : [location.pathname, qs.stringify(params)].join("?");
    navigate(pathname);
  }

  @autobind
  teacherAreaComp() {
    if (this.state.searchForm !== undefined) {
      switch (this.state.pageType) {
        case "teacherBinding":
          return (
            <TeacherBindingComp
              searchForm={this.state.searchForm}
              pageType={this.state.pageType}
              {...this.props}
            />
          );
        case "teacherShareReading":
          return (
            <TeacherShareReadingComp
              searchForm={this.state.searchForm}
              pageType={this.state.pageType}
              {...this.props}
            />
          );
        case "teacherClassReading":
          return (
            <TeacherClassReadingComp
              searchForm={this.state.searchForm}
              pageType={this.state.pageType}
              {...this.props}
            />
          );
        case "teacherRecommendEbook":
          return (
            <TeacherRecommendEbookComp
              searchForm={this.state.searchForm}
              pageType={this.state.pageType}
              {...this.props}
            />
          );
        default:
      }
    }
  }

  render() {
    let teacherInfo = "";
    if (
      "jumperrwd.teacherInfo." + this.state.pageType !==
      this.props.t("jumperrwd.teacherInfo." + this.state.pageType)
    ) {
      teacherInfo = this.props.t(
        "jumperrwd.teacherInfo." + this.state.pageType
      );
    }
    return (
      <Layout {...this.props}>
        <div className="main personal" id="center">
          <SearchBlock />
          <TeacherAreaTab
            {...this.props}
            searchForm={this.state.searchForm}
            tabList={this.state.tabList}
          />
          <section className="main_content">
            {teacherInfo !== "" && (
              <p className="personal_info">{teacherInfo}</p>
            )}
            <div className="container">
              {this.props.readerStore.auth && this.teacherAreaComp()}
            </div>
          </section>
        </div>
        <TeacherEnterLightBox
          ref={(c) => {
            this.teacherEnterLightBox = c;
          }}
          {...this.props}
        />
      </Layout>
    );
  }
}

export default TeacherArea;
