import React from "react";
import client from "lib/ApolloClient";
import { ApolloProvider, Query } from "react-apollo";
import gql from "graphql-tag";
import { autobind } from "react-decoration";
import qs from "query-string";
import navigate from "lib/navigate";
import { inject, observer } from "mobx-react";
import ReactLoading from "react-loading";

const getTeacherClassReadingQueryGroup = gql`
  query getTeacherClassReadingQueryGroup {
    getTeacherClassReadingQueryGroup {
      classList {
        bindingSn
        schoolID
        classTitle
      }
    }
  }
`;

@inject("appStore")
@observer
class TeacherClassReadingQueryGroupComp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      auth: props.readerStore.auth,
      refetch: null,
    };
  }

  @autobind
  changeBindingSn(bindingSn) {
    let { location, search } = this.props;
    let params = { ...search };
    params.bindingSn = bindingSn;
    navigate([location.pathname, qs.stringify(params)].join("?"));
  }

  render() {
    let { t, searchForm } = this.props;
    let listType = searchForm.listType || "classReadingInfo";
    searchForm.listType = listType;
    let bindingSn = searchForm.bindingSn || "";
    return (
      <>
        <div
          className="close"
          tabIndex="0"
          onClick={this.props.changeShowFilter.bind(this, false)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              this.props.changeShowFilter(false, e);
            }
          }}></div>
        <h2>班級閱讀情況</h2>
        <ApolloProvider client={client.jumperrwdClient}>
          <Query
            query={getTeacherClassReadingQueryGroup}
            fetchPolicy="network-only">
            {({ loading, error, data, refetch }) => {
              if (loading)
                return (
                  <div style={{ textAlign: "center" }}>
                    <ReactLoading
                      type="cylon"
                      height={"10%"}
                      width={"20%"}
                      color="#005D98"
                    />
                  </div>
                );
              if (error) return `Error!${error.message}`;
              if (this.state.refetch === null) {
                this.setState({ refetch: refetch });
              }

              if (data.getTeacherClassReadingQueryGroup) {
                let { classList } = data.getTeacherClassReadingQueryGroup;
                let classNum = 0;
                if (classList) {
                  classNum = classList.length;
                }
                if (classList && classList.length > 0 && bindingSn === "") {
                  this.changeBindingSn(classList[0].bindingSn);
                }
                return (
                  <div className="sort_list">
                    <ul>
                      <li>
                        <div
                          className="accordion_content"
                          style={{ display: "block" }}>
                          <ul>
                            <li className="">
                              <a href="javascript:;" tabIndex="0">
                                <div>
                                  總授課班級：{" "}
                                  <span className="orange">{classNum}</span>
                                </div>
                              </a>
                            </li>
                            {classList && (
                              <>
                                {classList.map((c) => {
                                  return (
                                    <li
                                      className={
                                        bindingSn === c.bindingSn ? "here" : ""
                                      }>
                                      班級：
                                      <a
                                        onClick={(e) => {
                                          e.preventDefault();
                                          this.changeBindingSn(c.bindingSn);
                                        }}
                                        onKeyDown={(e) => {
                                          if (e.key === "Enter") {
                                            this.changeBindingSn(c.bindingSn);
                                          }
                                        }}>
                                        {t(
                                          "jumperrwd.schoollist.name." +
                                            c.schoolID
                                        ) +
                                          " " +
                                          c.classTitle}
                                      </a>
                                    </li>
                                  );
                                })}
                              </>
                            )}
                          </ul>
                        </div>
                      </li>
                    </ul>
                  </div>
                );
              }
              return "";
            }}
          </Query>
        </ApolloProvider>
      </>
    );
  }
}

export default TeacherClassReadingQueryGroupComp;
