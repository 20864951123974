import React from "react";
import { autobind } from "react-decoration";
import navigate from "lib/navigate";
import qs from "query-string";
import { inject, observer } from "mobx-react";

@inject("appStore")
@observer
class CustListTab extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showTabs: false,
    };
  }

  @autobind
  changePageType(pageType, e) {
    e.preventDefault();
    let { location } = this.props;
    let params = {};
    params["pageType"] = pageType;
    navigate([location.pathname, qs.stringify(params)].join("?"));
    if (this.state.showTabs) {
      this.setState({
        showTabs: false,
      });
    }
  }

  @autobind
  handleShowTabs(e) {
    e.preventDefault();
    this.setState({
      showTabs: !this.state.showTabs,
    });
  }

  render() {
    let { searchForm, t, tabList } = this.props;
    let { pageType } = searchForm;
    let { showTabs } = this.state;
    return (
      <>
        <div className="main_tabSet">
          <div className="container">
            <button
              type="button"
              className="active_m"
              onClick={this.handleShowTabs}>
              {tabList.map((tab) => {
                if (tab === "bookmark" && pageType === "bookmark") {
                  return <>{t("jumperrwd.common.myCollection")}</>;
                } else if (tab === "resources" && pageType === "resources") {
                  return <>{t("jumperrwd.common.myResourceList")}</>;
                } else if (tab === "history" && pageType === "history") {
                  return <>{t("jumperrwd.common.searchHistory")}</>;
                } else if (tab === "sdi" && pageType === "sdi") {
                  return <>{t("jumperrwd.common.sdi")}</>;
                } else if (tab === "libHistory" && pageType === "libHistory") {
                  return <>{t("jumperrwd.common.libHistory")}</>;
                } else if (
                  tab === "ebookHistory" &&
                  pageType === "ebookHistory"
                ) {
                  return <>{t("jumperrwd.common.ebookHistory")}</>;
                } else if (tab === "ebookNotice" && pageType === "ebookNotice") {
                  return <>{t("jumperrwd.common.ebookNotice")}</>;
                } else if (tab === "myCommend" && pageType === "myCommend") {
                  return <>{t("jumperrwd.common.myCommend")}</>;
                } else if (tab === "myGrade" && pageType === "myGrade") {
                  return <>{t("jumperrwd.common.myGrade")}</>;
                }
                return <></>;
              })}
            </button>
            <ul className="tabs" style={{ display: showTabs ? "block" : "" }}>
              {tabList.map((tab) => {
                if (tab === "bookmark") {
                  return (
                    <li className={pageType === "bookmark" ? "active" : ""}>
                      <a
                        tabIndex="0"
                        role="button"
                        onClick={this.changePageType.bind(this, "bookmark")}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            this.changePageType("bookmark", e);
                          }
                        }}>
                        {t("jumperrwd.common.myCollection")}
                      </a>
                    </li>
                  );
                } else if (tab === "resources") {
                  return (
                    <li className={pageType === "resources" ? "active" : ""}>
                      <a
                        tabIndex="0"
                        onClick={this.changePageType.bind(this, "resources")}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            this.changePageType("resources", e);
                          }
                        }}>
                        {t("jumperrwd.common.myResourceList")}
                      </a>
                    </li>
                  );
                } else if (tab === "history") {
                  return (
                    <li className={pageType === "history" ? "active" : ""}>
                      <a
                        tabIndex="0"
                        onClick={this.changePageType.bind(this, "history")}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            this.changePageType("history", e);
                          }
                        }}>
                        {t("jumperrwd.common.searchHistory")}
                      </a>
                    </li>
                  );
                } else if (tab === "sdi") {
                  return (
                    <li className={pageType === "sdi" ? "active" : ""}>
                      <a
                        tabIndex="0"
                        onClick={this.changePageType.bind(this, "sdi")}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            this.changePageType("sdi", e);
                          }
                        }}>
                        {t("jumperrwd.common.sdi")}
                      </a>
                    </li>
                  );
                } else if (tab === "libHistory") {
                  return (
                    <li className={pageType === "libHistory" ? "active" : ""}>
                      <a
                        tabIndex="0"
                        onClick={this.changePageType.bind(this, "libHistory")}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            this.changePageType("libHistory", e);
                          }
                        }}>
                        {t("jumperrwd.common.libHistory")}
                      </a>
                    </li>
                  );
                } else if (tab === "ebookHistory") {
                  return (
                    <li className={pageType === "ebookHistory" ? "active" : ""}>
                      <a
                        tabIndex="0"
                        onClick={this.changePageType.bind(this, "ebookHistory")}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            this.changePageType("ebookHistory", e);
                          }
                        }}>
                        {t("jumperrwd.common.ebookHistory")}
                      </a>
                    </li>
                  );
                } else if (tab === "ebookNotice") {
                  return (
                    <li className={pageType === "ebookNotice" ? "active" : ""}>
                      <a
                        tabIndex="0"
                        onClick={this.changePageType.bind(this, "ebookNotice")}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            this.changePageType("ebookNotice", e);
                          }
                        }}>
                        {t("jumperrwd.common.ebookNotice")}
                      </a>
                    </li>
                  );
                } else if (tab === "myCommend") {
                  return (
                    <li className={pageType === "myCommend" ? "active" : ""}>
                      <a
                        tabIndex="0"
                        onClick={this.changePageType.bind(this, "myCommend")}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            this.changePageType("myCommend", e);
                          }
                        }}>
                        {t("jumperrwd.common.myCommend")}
                      </a>
                    </li>
                  );
                } else if (tab === "myGrade") {
                  return (
                    <li className={pageType === "myGrade" ? "active" : ""}>
                      <a
                        tabIndex="0"
                        onClick={this.changePageType.bind(this, "myGrade")}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            this.changePageType("myGrade", e);
                          }
                        }}>
                        {t("jumperrwd.common.myGrade")}
                      </a>
                    </li>
                  );
                }
                return <></>;
              })}
            </ul>
          </div>
        </div>
      </>
    );
  }
}

export default CustListTab;
