import gql from "graphql-tag";
import client from "lib/ApolloClient";
import { inject, observer } from "mobx-react";
import React from "react";
import { ApolloProvider, Query } from "react-apollo";
import { autobind } from "react-decoration";
import ReactLoading from "react-loading";
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

const getTeacherClassReadingAnalyze = gql`
  query getTeacherClassReadingAnalyze($searchForm: SearchForm) {
    getTeacherClassReadingInfo(Input: $searchForm) {
      year
      semester
      schoolID
      classTitle
      studentNum
      shareReadingNum
      startDate
      endDate
    }
    getTeacherClassReadingAnalyzeMonthlyBorrowCount(Input: $searchForm) {
      data {
        name
        value
      }
    }
    getTeacherClassReadingAnalyzeMonthlyBorrowPercent(Input: $searchForm) {
      data {
        name
        value
      }
    }
  }
`;

@inject("appStore")
@observer
class TeacherClassReadingAnalyzeComp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      auth: props.readerStore.auth,
      refetch: null,
    };
  }

  componentDidMount() {}

  UNSAFE_componentWillReceiveProps(props) {}

  @autobind
  getRefetch(refetch) {
    this.setState({ refetch: refetch });
  }

  render() {
    let { searchForm, t } = this.props;

    return (
      <ApolloProvider client={client.jumperrwdClient}>
        <Query
          query={getTeacherClassReadingAnalyze}
          fetchPolicy={"network-only"}
          variables={{
            searchForm: searchForm,
          }}
        >
          {({ loading, error, data, refetch }) => {
            if (loading)
              return (
                <div style={{ textAlign: "center" }}>
                  <ReactLoading
                    type={"cylon"}
                    height={"10%"}
                    width={"20%"}
                    color={"#005D98"}
                  />
                </div>
              );
            if (error) return `Error!${error.message}`;
            if (this.state.refetch === null) {
              this.setState({ refetch: refetch });
            }

            if (
              !data.getTeacherClassReadingInfo ||
              !data.getTeacherClassReadingAnalyzeMonthlyBorrowCount ||
              !data.getTeacherClassReadingAnalyzeMonthlyBorrowPercent
            ) {
              return "";
            }

            let {
              year = "",
              semester = "",
              schoolID = "",
              classTitle = "",
              studentNum = 0,
              shareReadingNum = 0,
              startDate = "",
              endDate = "",
            } = data.getTeacherClassReadingInfo;
            if (this.props.year !== year || this.props.semester !== semester) {
              if (this.props.setYearAndSemester) {
                this.props.setYearAndSemester(year, semester);
              }
            }

            const countData =
              data.getTeacherClassReadingAnalyzeMonthlyBorrowCount?.data;
            const percentData =
              data.getTeacherClassReadingAnalyzeMonthlyBorrowPercent?.data;

            return (
              <>
                {schoolID !== "" && (
                  <div className="function_panel">
                    <div className="result_amount">
                      <div className="class_result">
                        {t("jumperrwd.schoollist.name." + schoolID) +
                          " " +
                          classTitle}
                        ：共
                        <em>
                          <b>{studentNum}</b>
                        </em>
                        人
                      </div>
                      <div className="class_result">
                        已指派共讀書：共
                        <em>
                          <b>{shareReadingNum}</b>
                        </em>
                        種
                      </div>
                      <div className="class_result">
                        時間區間：
                        <em>
                          <b>{startDate}</b>
                        </em>
                        至
                        <em>
                          <b>{endDate}</b>
                        </em>
                      </div>
                    </div>
                  </div>
                )}
                <div className="divider">
                  <h2>班級閱讀走勢</h2>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "16px",
                    background: "#faddca",
                    margin: "16px",
                    padding: "16px",
                    borderRadius: "16px",
                  }}
                >
                  <div
                    style={{
                      flex: "1",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "100%",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                      }}
                    >
                      <div
                        style={{
                          background: "#3487C0CC",
                          fontSize: "24px",
                          color: "#FFFFFF",
                          borderRadius: "12px 12px 0 0",
                          padding: "16px",
                          marginBottom: "-16px",
                        }}
                      >
                        每月借閱冊數
                      </div>
                    </div>
                    <ResponsiveContainer
                      width={"100%"}
                      aspect={1}
                      style={{
                        backgroundColor: "#ffffff",
                        borderRadius: "16px",
                        paddingTop: "16px",
                        paddingRight: "16px",
                        paddingLeft: "8px",
                        paddingBottom: "8px",
                      }}
                    >
                      <LineChart data={countData}>
                        <Line
                          type={"monotone"}
                          dataKey={"value"}
                          stroke={"#3487C0"}
                        />
                        <CartesianGrid
                          stroke={"#ccc"}
                          strokeDasharray={"5 5"}
                        />
                        <Tooltip />
                        <XAxis dataKey={"name"} />
                        <YAxis domain={[0, 300]} tickCount={7} />
                      </LineChart>
                    </ResponsiveContainer>
                    <div
                      style={{
                        textAlign: "center",
                      }}
                    >
                      ※借閱冊數計算方式：學生每借閱1次計算為1冊
                    </div>
                  </div>
                  <div
                    style={{
                      flex: "1",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "100%",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                      }}
                    >
                      <div
                        style={{
                          background: "#37CC3ECC",
                          fontSize: "24px",
                          color: "#FFFFFF",
                          borderRadius: "12px 12px 0 0",
                          padding: "16px",
                          marginBottom: "-16px",
                        }}
                      >
                        每月借閱率
                      </div>
                    </div>
                    <ResponsiveContainer
                      width={"100%"}
                      aspect={1}
                      style={{
                        backgroundColor: "#ffffff",
                        borderRadius: "16px",
                        paddingTop: "16px",
                        paddingRight: "16px",
                        paddingLeft: "8px",
                        paddingBottom: "8px",
                      }}
                    >
                      <LineChart data={percentData}>
                        <Line
                          type={"monotone"}
                          dataKey={"value"}
                          stroke={"#37CC3E"}
                        />
                        <CartesianGrid
                          stroke={"#ccc"}
                          strokeDasharray={"5 5"}
                        />
                        <Tooltip />
                        <XAxis dataKey={"name"} />
                        <YAxis domain={[0, 100]} tickCount={6} />
                      </LineChart>
                    </ResponsiveContainer>
                    <div
                      style={{
                        textAlign: "center",
                      }}
                    >
                      ※借閱率計算方式：當月有借閱書籍的學生人數 / 班級學生人數
                    </div>
                  </div>
                </div>
              </>
            );
          }}
        </Query>
      </ApolloProvider>
    );
  }
}

export default TeacherClassReadingAnalyzeComp;
