import React from "react";
import { autobind } from "react-decoration";
import navigate from "lib/navigate";
import qs from "query-string";
import { inject, observer } from "mobx-react";

@inject("appStore")
@observer
class TeacherAreaTab extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showTabs: false,
    };
  }

  @autobind
  changePageType(pageType, e) {
    e.preventDefault();
    let { location } = this.props;
    let params = {};
    params["pageType"] = pageType;
    navigate([location.pathname, qs.stringify(params)].join("?"));
    if (this.state.showTabs) {
      this.setState({
        showTabs: false,
      });
    }
  }

  @autobind
  handleShowTabs(e) {
    e.preventDefault();
    this.setState({
      showTabs: !this.state.showTabs,
    });
  }

  render() {
    let { searchForm, t, tabList } = this.props;
    let { pageType } = searchForm;
    let { showTabs } = this.state;
    return (
      <>
        <div className="main_tabSet">
          <div className="container">
            <button
              type="button"
              className="active_m"
              onClick={this.handleShowTabs}>
              {tabList.map((tab) => {
                if (tab === "teacherBinding" && pageType === "teacherBinding") {
                  return <>{"教師專區"}</>;
                } else if (tab === "teacherShareReading" && pageType === "teacherShareReading") {
                  return <>{"共讀書單"}</>;
                } else if (tab === "teacherClassReading" && pageType === "teacherClassReading") {
                  return <>{"班級閱讀情況"}</>;
                } else if (tab === "teacherRecommendEbook" && pageType === "teacherRecommendEbook") {
                  return <>{"班級閱讀情況"}</>;
                }
                return <></>;
              })}
            </button>
            <ul className="tabs" style={{ display: showTabs ? "block" : "" }}>
              {tabList.map((tab) => {
                if (tab === "teacherBinding") {
                  return (
                    <li className={pageType === "teacherBinding" ? "active" : ""}>
                      <a
                        tabIndex="0"
                        role="button"
                        onClick={this.changePageType.bind(this, "teacherBinding")}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            this.changePageType("teacherBinding", e);
                          }
                        }}>
                        教師專區
                      </a>
                    </li>
                  );
                } else if (tab === "teacherShareReading") {
                  return (
                    <li className={pageType === "teacherShareReading" ? "active" : ""}>
                      <a
                        tabIndex="0"
                        onClick={this.changePageType.bind(this, "teacherShareReading")}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            this.changePageType("teacherShareReading", e);
                          }
                        }}>
                        共讀書單
                      </a>
                    </li>
                  );
                } else if (tab === "teacherClassReading") {
                  return (
                    <li className={pageType === "teacherClassReading" ? "active" : ""}>
                      <a
                        tabIndex="0"
                        onClick={this.changePageType.bind(this, "teacherClassReading")}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            this.changePageType("teacherClassReading", e);
                          }
                        }}>
                        班級閱讀情況
                      </a>
                    </li>
                  );
                } else if (tab === "teacherRecommendEbook") {
                  return (
                    <li className={pageType === "teacherRecommendEbook" ? "active" : ""}>
                      <a
                        tabIndex="0"
                        onClick={this.changePageType.bind(this, "teacherRecommendEbook")}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            this.changePageType("teacherRecommendEbook", e);
                          }
                        }}>
                        推薦電子書
                      </a>
                    </li>
                  );
                }
                return <></>;
              })}
            </ul>
          </div>
        </div>
      </>
    );
  }
}

export default TeacherAreaTab;
