import React from "react";
import IntegrationDataList from "components/list/IntegrationDataList";
import TeacherNetworkRecommendDisplayComp from "components/teacher/TeacherNetworkRecommendDisplayComp";
import gql from "graphql-tag";
import { inject, observer } from "mobx-react";
import { autobind } from "react-decoration";
import navigate from "lib/navigate";
import qs from "query-string";

const getRecommendSearchList = gql`
  query startRecommendSearch($searchForm: IntegrationSearchForm) {
    startRecommendSearch(Input: $searchForm) {
      display {
        field
        name
        position
        type
      }
      sort {
        name
        order
        field
        default
      }
      list {
        values {
          ref {
            key
            value
          }
        }
      }
      options {
        max
        pagesize
        showpage
      }
      info {
        total
        count
        limit
        pageNo
        totalPage
        start
        filterPid
        searchKeys
        showNext
        showPrevious
        iTotal
        iFilterTotal
        process
        fetchMoreProcess
        total
        iTotal
        pid
        isExpired
        showNext
        fetchMore
        userCollectionList

        pages {
          active
          val
        }
      }
    }
  }
`;

@inject("appStore")
@observer
class TeacherNetworkRecommendEbookComp extends React.Component {
  constructor(props) {
    super(props);
    let params = qs.parseUrl(this.props.location.href).query;

    let {
      searchInput,
      searchField,
      op,
      db,
      filterField,
      filterValue,
      pid,
      filterDBID,
      fetch,
      pageNo = 1,
      sort,
      mode,
    } = params;

    let dbTemp = db;
    let searchInputTemp = searchInput;
    let searchFieldTemp = searchField;

    searchInput = searchInput || [];
    searchField = searchField || [];
    filterField = filterField || [];
    filterValue = filterValue || [];
    op = op || [];
    db = db || [];

    if (typeof searchInput === "string") {
      searchInput = [searchInput];
    }
    if (typeof searchField === "string") {
      searchField = [searchField];
    }
    if (typeof filterField === "string") {
      filterField = [filterField];
    }
    if (typeof filterValue === "string") {
      filterValue = [filterValue];
    }
    if (typeof op === "string") {
      op = [op];
    }
    if (typeof db === "string") {
      db = [db];
    }

    this.state = {
      searchForm: {
        searchInput,
        searchField,
        op,
        filterField,
        filterValue,
        pid,
        db,
        filterDBID,
        fetch,
        limit: 30,
        pageNo,
        sort,
        mode,
      },
      auth: props.readerStore.auth,
      db: dbTemp || "10E20D100",
      searchField: searchFieldTemp || "TI",
      searchInput: searchInputTemp || "",
    };
  }

  componentDidMount() {}

  UNSAFE_componentWillReceiveProps() {
    let params = qs.parseUrl(window.location.href).query;
    let {
      searchInput,
      searchField,
      op,
      filterField,
      filterValue,
      pid,
      db,
      fetch,
      filterDBID,
      pageNo = 1,
      sort,
      mode,
    } = params;

    searchInput = searchInput || [];
    searchField = searchField || [];
    op = op || [];
    db = db || [];
    filterField = filterField || [];
    filterValue = filterValue || [];

    if (typeof searchInput === "string") {
      searchInput = [searchInput];
    }
    if (typeof searchField === "string") {
      searchField = [searchField];
    }
    if (typeof op === "string") {
      op = [op];
    }
    if (typeof db === "string") {
      db = [db];
    }
    if (typeof filterField === "string") {
      filterField = [filterField];
    }
    if (typeof filterValue === "string") {
      filterValue = [filterValue];
    }

    this.setState({
      searchForm: {
        searchInput,
        searchField,
        op,
        filterField,
        filterValue,
        pid,
        db,
        filterDBID,
        fetch,
        limit: 30,
        pageNo,
        sort,
        mode,
      },
    });
  }

  @autobind
  onQueryCompleted(data) {
    console.info("onQueryCompleted");
    const { pid, filterPid, process } = data.startRecommendSearch.info;
    const { searchForm } = this.state;

    if (process) {
      if (pid !== searchForm.pid) {
        this.setState({ searchForm: { ...searchForm, pid } }, () => {
          if (window.IntervalID === null || window.IntervalID === undefined) {
            window.IntervalID = setInterval(this.refetch, 1000);
          }
          let params = qs.parseUrl(this.props.location.href).query;
          params.pid = pid;
          navigate(
            [this.props.location.pathname, qs.stringify(params)].join("?")
          );
        });
      }
    } else {
      let params = qs.parseUrl(this.props.location.href).query;
      if (filterPid !== params.filterPid) {
        params.filterPid = filterPid;
        params.pid = pid;
        navigate(
          [this.props.location.pathname, qs.stringify(params)].join("?")
        );
      }
      clearInterval(window.IntervalID);
      window.IntervalID = null;
    }
    console.log("onQueryCompleted refetch onQueryCompleted");
  }

  @autobind
  handleDBChange(e) {
    e.preventDefault();
    this.setState({ db: e.target.value });
  }

  @autobind
  handleSearchFieldChange(e) {
    e.preventDefault();
    this.setState({ searchField: e.target.value });
  }

  @autobind
  handleSearchInput(e) {
    e.preventDefault();
    this.setState({ searchInput: e.target.value });
  }

  getRefetch = (refetch) => {
    this.refetch = () => {
      console.log("PID:", this.state.searchForm.pid);
      refetch();
    };
  };

  @autobind
  onSubmit(e) {
    e.preventDefault();
    let { db, searchField, searchInput } = this.state;

    let params = {
      searchInput,
      searchField,
      db,
      pageType: "teacherRecommendEbook",
      actionType: "networkRecommend",
    };

    navigate(["/teacherArea", qs.stringify(params)].join("?"));
  }

  render() {
    let { t } = this.props;
    let { db, searchField, searchInput, searchForm } = this.state;
    return (
      <>
        <div className="list_all">
          <div className="content">
            <form onSubmit={this.onSubmit}>
              <div className="form_grid form">
                <div className="form_grp form_inline">
                  <select
                    className="form_select"
                    name="db"
                    value={db}
                    onChange={this.handleDBChange}>
                    <option value="10E20D100">HyRead電子書</option>
                    <option value="11V223100">華藝</option>
                    <option value="11W22F100">UDN電子書</option>
                    <option value="L7S201100">電子書服務平台</option>
                  </select>
                  <select
                    className="form_select"
                    name="searchField"
                    value={searchField}
                    onChange={this.handleSearchFieldChange}>
                    <option value="TI">{t("hyint.common.table.title")}</option>
                    <option value="AU">{t("hyint.common.table.author")}</option>
                    <option value="SUBJECT">
                      {t("hyint.common.table.subject")}
                    </option>
                    <option value="PUB">
                      {t("hyint.common.table.publisher")}
                    </option>
                    <option value="ISSN">{t("hyint.common.table.issn")}</option>
                    <option value="ISBN">{t("hyint.common.table.isbn")}</option>
                    <option value="KW">{t("hyint.common.table.kw")}</option>
                  </select>
                  <input
                    name="stitle"
                    type="text"
                    className="searchbook"
                    value={searchInput}
                    onChange={this.handleSearchInput}
                  />
                  <div className="btn_grp form_inline">
                    <input name="" type="submit" title="查詢" value="查詢" />
                  </div>
                  <hr />
                  {searchForm.db && searchForm.db.length > 0 && (
                    <IntegrationDataList
                      query={getRecommendSearchList}
                      variables={{
                        searchForm,
                      }}
                      getRefetch={this.getRefetch}
                      fetchPolicy={"network-only"}
                      displayComp={TeacherNetworkRecommendDisplayComp}
                      pagination={"IntegrationPagination"}
                      onQueryCompleted={this.onQueryCompleted}
                    />
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </>
    );
  }
}

export default TeacherNetworkRecommendEbookComp;
