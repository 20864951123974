import React from "react";
import { inject, observer } from "mobx-react";
import { autobind } from "react-decoration";
import client from "lib/ApolloClient";
import ReactLoading from "react-loading";
import { ApolloProvider, Query } from "react-apollo";
import gql from "graphql-tag";
import Link from "lib/Link";

const getTeacherJournalFullTextItemList = gql`
  query getTeacherJournalFullTextItemList($searchForm: SearchForm) {
    getTeacherJournalFullTextItemList(Input: $searchForm) {
      journalID
      readingSn
      url
      resourceID
      ebName
      EnEbName
      ftStartDate
      ftEndDate
      pbulisherSN
      publisherName
      showJournalLink
    }
  }
`;

@inject("appStore", "readerStore")
@observer
class JournalFullTextItem extends React.Component {
  constructor(props) {
    super(props);
    let { appStore } = props;
    let { globalConfig } = appStore;
    let showDBLink = true;
    let showDBLinkString =
      globalConfig["jumperrwd.journal.fullTextShowDBLink"] || "";
    if (showDBLinkString === "0") {
      showDBLink = false;
    }
    this.state = {
      showDBLink: showDBLink,
    };
  }

  render() {
    let { journalID, variables, language, t, handleClick } = this.props;
    let { searchForm } = variables;
    let { resourceType } = searchForm;
    return (
      <>
        <ApolloProvider client={client.jumperrwdClient}>
          <Query
            query={getTeacherJournalFullTextItemList}
            fetchPolicy="network-only"
            variables={{
              searchForm: {
                id: journalID,
                resourceType: resourceType,
                language: language,
              },
            }}>
            {({ loading, error, data, refetch }) => {
              if (loading)
                return (
                  <div style={{ textAlign: "center" }}>
                    <ReactLoading
                      type="cylon"
                      height={"10%"}
                      width={"20%"}
                      color="#005D98"
                    />
                  </div>
                );
              if (error) return `Error!${error.message}`;
              if (this.state.refetch === null) {
                this.setState({ refetch: refetch });
              }
              if (data.getTeacherJournalFullTextItemList) {
                let itemList = data.getTeacherJournalFullTextItemList;
                let out = [];
                itemList.map((item) => {
                  let {
                    journalID: iJournalID,
                    readingSn,
                    url,
                    resourceID,
                    ebName,
                    EnEbName,
                    ftStartDate,
                    ftEndDate,
                    pbulisherSN,
                    publisherName,
                    showJournalLink,
                  } = item;
                  let endDate = "";
                  if (ftEndDate.toLowerCase() === "current") {
                    endDate = t("jumper.common.current");
                  } else {
                    endDate = ftEndDate;
                  }
                  let pName = "";
                  if (pbulisherSN !== 0) {
                    pName = publisherName + "-";
                  }
                  let journalURL = "#";
                  if (url !== "") {
                    journalURL =
                      "/sendURLApiV3?mode=click&type=0&journal_id=" + iJournalID;
                  }
                  let resourceName =
                    language === "en" && EnEbName !== "" ? EnEbName : ebName;
                  out.push(
                    <li>
                      <div className="title">
                        {t("jumper.common.fulltext")}：
                      </div>
                      <div>
                        {ftStartDate !== "" && endDate !== "" && (
                          <>
                            {ftStartDate}-{endDate}
                          </>
                        )}
                        {" " + t("jumperrwd.fulltext.included") + " "}
                        {pName}
                        {this.state.showDBLink ? (
                          <a
                            tabIndex="0"
                            onClick={(e) => {
                              e.preventDefault();
                              handleClick("databases", {
                                id: resourceID,
                              });
                            }}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                handleClick("databases", {
                                  id: resourceID,
                                });
                              }
                            }}>
                            {resourceName}
                          </a>
                        ) : (
                          <>{resourceName}</>
                        )}
                        {showJournalLink && (
                          <button type="button" className="btn_normal">
                            <Link
                              href={journalURL}
                              target={journalURL === "#" ? "" : "_blank"}
                              title={
                                journalURL === "#"
                                  ? ""
                                  : t("jumperrwd.common.openNewWindow")
                              }>
                              {resourceType !== "ebook"
                                ? t("jumperrwd.common.journalLink")
                                : t("jumperrwd.common.ebookLink")}
                            </Link>
                          </button>
                        )}
                        <button
                          className="btn_borrow"
                          type="button"
                          onClick={(e) => {
                            e.preventDefault();
                            handleClick("assignClass", { sn: readingSn.toString() });
                          }}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              handleClick("assignClass", { sn: readingSn.toString() });
                            }
                          }}>
                          <a tabIndex="0">指定分配</a>
                        </button>
                      </div>
                    </li>
                  );
                  return "";
                });
                return out;
              }
              return "";
            }}
          </Query>
        </ApolloProvider>
      </>
    );
  }
}

export default JournalFullTextItem;
