import React from "react";
import Layout from "components/layout";
import { withI18next } from "lib/withI18next";
import { autobind } from "react-decoration";
import gql from "graphql-tag";
import client from "lib/ApolloClient";
import { page } from "components/page";
import navigate from "lib/navigate";
import axios from "axios";

const setHySSOLogin = gql`
  mutation setHySSOLogin($form: HySSOLoginForm) {
    result: setHySSOLogin(Input: $form) {
      success
    }
  }
`;

@withI18next(["common"])
@page
class HySSOLogin extends React.Component {
  constructor(props) {
    super(props);
    let alertHiddenString =
      props.appStore.globalConfig["jumperrwd.login.alertHidden"];
    let alertHidden = alertHiddenString === "1";
    this.state = {
      auth: props.readerStore.auth,
      alertHidden: alertHidden,
    };
  }

  componentDidMount() {
    this.hySSOLogin(this.props);
  }

  /*UNSAFE_componentWillReceiveProps(props) {
    if (this.state.auth !== props.readerStore.auth) {
      this.setState({ auth: props.readerStore.auth });
      if (props.readerStore.auth) {
        navigate("/");
      } else {
        this.hySSOLogin(props);
      }
    }
  }*/

  @autobind
  handleChangeLanguage(lng) {
    axios
      .get("/api/jumperrwdWs/setSession?key=locales&value=" + lng)
      .then((res) => {});
    const { i18n } = this.props;
    i18n.changeLanguage(lng);
  }

  @autobind
  async hySSOLogin(props) {
    let { search } = props;
    let params = { ...search };
    let { sid } = params;
    let url2 = this.props.readerStore.session.OLOGINRURL || "";
    let lang2 = this.props.readerStore.session.OLOGINRLANG || "";

    if (url2 !== "") {
      await axios
        .get(`/api/jumperrwdWs/setSession?key=OLOGINRURL&value=`)
        .then((res) => {});
    }
    if (lang2 !== "") {
      await axios
        .get(`/api/jumperrwdWs/setSession?key=OLOGINRLANG&value=`)
        .then((res) => {});
    }
    if (sid !== undefined && sid !== "") {
      client.jumperrwdClient
        .mutate({
          mutation: setHySSOLogin,
          variables: {
            form: {
              sid: sid,
            },
          },
        })
        .then((res) => {
          let { result } = res.data;
          if (result.success) {
            if (!this.state.alertHidden) {
              alert(this.props.t("jumperrwd.login.loginSuccess"));
            }
            this.props.readerStore.syncSessionCookie().then(() => {
              if (lang2 !== "") {
                this.handleChangeLanguage(lang2)
              }
              if (url2 !== "") {
                window.location.href = url2;
              } else {
                navigate("/");
              }
            });
          } else {
            navigate("/");
          }
        });
    } else {
      navigate("/");
    }
  }

  render() {
    let { t, isprivate, i18n } = this.props;
    return (
      <Layout t={t} i18n={i18n} isprivate={isprivate} hiddenBg={true}>
        <div
          ref={(c) => {
            this.container = c;
          }}
        />
      </Layout>
    );
  }
}

export default HySSOLogin;
