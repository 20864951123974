import React from "react";
import LightBox from "components/common/LightBox";
import qs from "query-string";
import navigate from "lib/navigate";

@LightBox
class ResourceGroupLightBox extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ...props,
    };
  }

  UNSAFE_componentWillReceiveProps(props) {
    if (props.title !== this.state.title) {
      this.setState({ title: props.title });
    }
  }

  beforeOpen = () => {
    let { search } = this.props;
    let params = { ...search };
    let { filterField = [], filterValue = [] } = params;
    filterField = [].concat(filterField || []);
    filterValue = [].concat(filterValue || []);
    let filtersData = {};
    let checkFilter = {};

    filterField.forEach((field, k) => {
      let value = filterValue[k];
      let key = `${value}`;
      checkFilter[key] = true;
      filtersData[key] = {
        field,
      };
    });

    this.setState({
      filtersData,
      checkFilter,
    });
  };

  addFilter = (item) => {
    let { value } = item;
    let { checkFilter, filtersData } = this.state;
    let key = `${value}`;
    if (checkFilter[key]) {
      delete checkFilter[key];
    } else {
      checkFilter[key] = true;
      filtersData[key] = {
        field: this.state.index,
      };
    }
    this.setState({ checkFilter: checkFilter });
  };

  submit = (e) => {
    e.preventDefault();
    let { location, search } = this.props;
    let params = { ...search };
    let OldFilterValue = [].concat(params.filterValue);
    delete params.filterField;
    delete params.filterValue;
    const { checkFilter, filtersData } = this.state;

    let filterField = [],
      filterValue = [];
    Object.keys(checkFilter).map((key) => {
      let { field } = filtersData[key];
      filterField.push(field);
      filterValue.push(key);
      return "";
    });

    let hasNew = false;
    filterValue.forEach((of) => {
      hasNew = hasNew || OldFilterValue.indexOf(of) === -1;
    });

    if (hasNew) {
      delete params.filterPid;
      params.filterField = filterField;
      params.filterValue = filterValue;
      navigate([location.pathname, qs.stringify(params)].join("?"));
    }
  };

  render() {
    let { t, index, close } = this.props;
    let { checkFilter = {} } = this.state;
    return (
      <form onSubmit={this.submit}>
        <div className="check_grp scroll_content">
          {this.state.data.map((item, key) => {
            const { title, cnt, value } = item;
            return (
              <>
                <label htmlFor={`${index}-${item.value}`}>
                  <input
                    type="checkbox"
                    id={`${index}-${item.value}`}
                    name={index}
                    value={`${index}-${item.value}`}
                    checked={checkFilter[value]}
                    onChange={this.addFilter.bind(this, item)}
                  />
                  {title}
                  <span>{cnt > -1 && <>{"(" + cnt + ")"}</>}</span>
                </label>
              </>
            );
          })}
        </div>
        <div className="btn_grp">
          <button
            tabIndex="0"
            className="btn btn-reset"
            style={{ float: "none" }}
            onClick={(e) => {
              e.preventDefault();
              close();
            }}>
            {t("jumperrwd.common.cancel")}
          </button>
          <button
            tabIndex="0"
            className="btn btn-submit"
            style={{ float: "none" }}
            onClick={this.submit}>
            {t("jumperrwd.common.confirm")}
          </button>
        </div>
      </form>
    );
  }
}

export default ResourceGroupLightBox;
