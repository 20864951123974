import React from "react";
import ReactLoading from "react-loading";
import client from "lib/ApolloClient";
import { ApolloProvider, Query } from "react-apollo";
import gql from "graphql-tag";
import { inject, observer } from "mobx-react";
import { autobind } from "react-decoration";
import navigate from "lib/navigate";
import qs from "query-string";
import TeacherForStudentReadingLightBox from "components/teacher/TeacherForStudentReadingLightBox";

const getTeacherClassReadingInfo = gql`
  query getTeacherClassReadingInfo($searchForm: SearchForm) {
    getTeacherClassReadingInfo(Input: $searchForm) {
      year
      semester
      schoolID
      classTitle
      studentNum
      shareReadingNum
      startDate
      endDate
      studentList {
        userID
        name
        bindLendNum
        bindLendPercent
        totalLendNum
      }
    }
  }
`;

@inject("appStore")
@observer
class TeacherClassReadingInfoComp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      auth: props.readerStore.auth,
      bindingSn: "",
      userID: "",
      refetch: null,
    };
  }

  componentDidMount() {}

  UNSAFE_componentWillReceiveProps(props) {}

  @autobind
  changeActionType(actionType) {
    let { location } = this.props;
    let params = {
      pageType: "teacherClassReading",
      actionType: actionType,
    };
    navigate([location.pathname, qs.stringify(params)].join("?"));
  }

  @autobind
  getRefetch(refetch) {
    this.setState({ refetch: refetch });
  }

  @autobind
  openStudentReadingLightBox(bindingSn, userID) {
    this.setState({
      bindingSn,
      userID,
    })
    this.teacherForStudentReadingLightBox.popup();
  }

  render() {
    let { searchForm, t } = this.props;
    let actionType = searchForm.actionType || "classReadingInfo";
    searchForm.actionType = actionType;
    let bindingSn = searchForm.bindingSn || "";

    return (
      <>
        <ApolloProvider client={client.jumperrwdClient}>
          <Query
            query={getTeacherClassReadingInfo}
            fetchPolicy="network-only"
            variables={{
              searchForm: searchForm,
            }}>
            {({ loading, error, data, refetch }) => {
              if (loading)
                return (
                  <div style={{ textAlign: "center" }}>
                    <ReactLoading
                      type="cylon"
                      height={"10%"}
                      width={"20%"}
                      color="#005D98"
                    />
                  </div>
                );
              if (error) return `Error!${error.message}`;
              if (this.state.refetch === null) {
                this.setState({ refetch: refetch });
              }

              if (data.getTeacherClassReadingInfo) {
                let {
                  year = "",
                  semester = "",
                  schoolID = "",
                  classTitle = "",
                  studentNum = 0,
                  shareReadingNum = 0,
                  startDate = "",
                  endDate = "",
                  studentList,
                } = data.getTeacherClassReadingInfo;
                if (
                  this.props.year !== year ||
                  this.props.semester !== semester
                ) {
                  if (this.props.setYearAndSemester) {
                    this.props.setYearAndSemester(year, semester);
                  }
                }
                return (
                  <>
                    {schoolID !== "" && (
                      <div className="function_panel">
                        <div className="result_amount">
                          <div className="class_result">
                            {t("jumperrwd.schoollist.name." + schoolID) +
                              " " +
                              classTitle}
                            ：共
                            <em>
                              <b>{studentNum}</b>
                            </em>
                            人
                          </div>
                          <div className="class_result">
                            已指派共讀書：共
                            <em>
                              <b>{shareReadingNum}</b>
                            </em>
                            種
                          </div>
                          <div className="class_result">
                            時間區間：
                            <em>
                              <b>{startDate}</b>
                            </em>
                            至
                            <em>
                              <b>{endDate}</b>
                            </em>
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="detail_block">
                      <div className="table_list">
                        <table border="1">
                          <tbody>
                            <tr>
                              <th>姓名</th>
                              <th>已借閱共讀書</th>
                              <th>共讀書借閱率</th>
                              <th>累積借閱冊數</th>
                            </tr>
                            {studentList &&
                              studentList.map((s) => {
                                return (
                                  <>
                                    <tr>
                                      <td>{s.name}</td>
                                      <td>
                                        <a
                                          href="javascript:;"
                                          onClick={(e) => {
                                            e.preventDefault();
                                            this.openStudentReadingLightBox(bindingSn, s.userID);
                                          }}
                                          onKeyDown={(e) => {
                                            if (e.key === "Enter") {
                                              this.openStudentReadingLightBox(bindingSn, s.userID);
                                            }
                                          }}>
                                          {s.bindLendNum + "種"}
                                        </a>
                                      </td>
                                      <td>{s.bindLendPercent + "％"}</td>
                                      <td>{s.totalLendNum}</td>
                                    </tr>
                                  </>
                                );
                              })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </>
                );
              }
              return "";
            }}
          </Query>
        </ApolloProvider>
        <TeacherForStudentReadingLightBox
          ref={(c) => {
            this.teacherForStudentReadingLightBox = c;
          }}
          {...this.props}
          bindingSn={this.state.bindingSn}
          userID={this.state.userID}
        />
      </>
    );
  }
}

export default TeacherClassReadingInfoComp;
