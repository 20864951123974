import React from "react";
import client from "lib/ApolloClient";
import ReactLoading from "react-loading";
import { ApolloProvider, Query } from "react-apollo";
import gql from "graphql-tag";
import ReactHtmlParser from "react-html-parser";
import { autobind } from "react-decoration";
import navigate from "lib/navigate";
import qs from "query-string";
import JournalResourceListComp from "components/resource/JournalResourceListComp";
import ResourceListTab from "components/resource/ResourceListTab";
import SearchBlock from "components/search/SearchBlock";

const getExhibitionDetail = gql`
  query getExhibitionDetail($searchForm: ExhibitionDetailForm) {
    getExhibitionDetail(Input: $searchForm) {
      subject
      photo
      onlineDate
      offlineDate
      content
      exhibitionSubList {
        exhibitionID
        subject
        photo
        onlineDate
        offlineDate
      }
      exhibitionPath {
        exhibitionID
        subject
      }
    }
  }
`;

class ExhibitionComp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  @autobind
  resourceListComp() {
    if (this.props.searchForm !== undefined) {
      switch (this.props.resourceType) {
        case "journal":
          return (
            <JournalResourceListComp
              searchForm={this.props.searchForm}
              resourceType={this.props.resourceType}
              pageType={this.props.pageType}
              {...this.props}
            />
          );
        case "paper_journal":
          return (
            <JournalResourceListComp
              searchForm={this.props.searchForm}
              resourceType={this.props.resourceType}
              pageType={this.props.pageType}
              {...this.props}
            />
          );
        case "ebook":
          return (
            <JournalResourceListComp
              searchForm={this.props.searchForm}
              resourceType={this.props.resourceType}
              pageType={this.props.pageType}
              {...this.props}
            />
          );
        default:
      }
    }
  }

  @autobind
  setExhibitionID(exhibitionID, e) {
    e.preventDefault();
    let { location } = this.props;
    let params = {};
    params.exhibitionID = exhibitionID;
    navigate([location.pathname, qs.stringify(params)].join("?"));
  }

  render() {
    let searchBlockType = "resource";
    let { t, searchForm } = this.props;
    let { exhibitionID = "" } = searchForm;
    let exhibitionIDInt = 0;
    if (exhibitionID !== "") {
      exhibitionIDInt = parseInt(exhibitionID);
    }
    return (
      <>
        <ApolloProvider client={client.jumperrwdClient}>
          <Query
            query={getExhibitionDetail}
            fetchPolicy="network-only"
            variables={{
              searchForm: {
                id: exhibitionIDInt,
              },
            }}>
            {({ loading, error, data, refetch }) => {
              if (loading)
                return (
                  <div style={{ textAlign: "center" }}>
                    <ReactLoading
                      type="cylon"
                      height={"10%"}
                      width={"20%"}
                      color="#005D98"
                    />
                  </div>
                );
              if (error) return `Error!${error.message}`;
              if (data && data.getExhibitionDetail) {
                let { getExhibitionDetail } = data;
                let {
                  subject,
                  photo,
                  onlineDate,
                  offlineDate,
                  content,
                  exhibitionSubList,
                  exhibitionPath,
                } = getExhibitionDetail;
                let photoPath = "/file/downloadFile/exhibition/" + photo;
                let hasSub = exhibitionSubList && exhibitionSubList.length > 0;
                let themeSectionClass = "section col-12 theme_book";
                let pageClass = "insidepages";
                if (exhibitionIDInt === 0) {
                  pageClass = "mainpages";
                }
                if (hasSub) {
                  if (exhibitionIDInt === 0) {
                    themeSectionClass = "section col-12 theme_secondbook";
                  } else {
                    themeSectionClass = "section col-12 theme_thirdbook";
                  }
                }
                return (
                  <>
                    {exhibitionIDInt === 0 && <SearchBlock />}
                    {subject !== "" && (
                      <section className={themeSectionClass}>
                        <div className="container">
                          <div className="col">
                            <div className="theme_data">
                              <div className="images">
                                <img alt={subject} src={photoPath} />
                              </div>
                              <h3>{subject}</h3>
                              <ul className="datalist">
                                <li>
                                  {ReactHtmlParser(
                                    t("jumperrwd.exhibition.exhibtionTime", {
                                      onlineDate: onlineDate,
                                      offlineDate: offlineDate,
                                    })
                                  )}
                                </li>
                              </ul>
                              <div className="explain">
                                {ReactHtmlParser(content)}
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                    )}
                    {((exhibitionPath && exhibitionPath.length > 0) ||
                      exhibitionIDInt === 0) && (
                      <div className="main_content erm">
                        <div className="container">
                          <div className="search_result">
                            <h2>
                              {exhibitionPath &&
                                exhibitionPath.map((pathItem, k) => {
                                return (
                                  <>
                                    {k !== 0 && <>{" / "}</>}
                                    <a
                                      tabIndex="0"
                                      onClick={this.setExhibitionID.bind(
                                        this,
                                        pathItem.exhibitionID
                                      )}
                                      onKeyDown={(e) => {
                                        if (e.key === "Enter") {
                                          this.setExhibitionID(
                                            pathItem.exhibitionID,
                                            e
                                          );
                                        }
                                      }}>
                                      {pathItem.subject}
                                    </a>
                                  </>
                                );
                              })}
                              {exhibitionIDInt === 0 && <>{"主題書展專區"}</>}
                            </h2>
                          </div>
                        </div>
                      </div>
                    )}
                    {hasSub && (
                      <div className={pageClass}>
                        <section className="section col-12 theme_eBook">
                          <div className="container">
                            <div className="col">
                              {exhibitionSubList.map((es) => {
                                return (
                                  <div className="featured_data">
                                    <a
                                      tabIndex="0"
                                      onClick={this.setExhibitionID.bind(
                                        this,
                                        es.exhibitionID
                                      )}
                                      onKeyDown={(e) => {
                                        if (e.key === "Enter") {
                                          this.setExhibitionID(
                                            es.exhibitionID,
                                            e
                                          );
                                        }
                                      }}>
                                      <div className="datablock_top">
                                        <div className="maskColor">
                                          <img
                                            src="/file/images/book_overlay.png"
                                            className="img-fluid maskPic w-100 imageLoaded"
                                            alt="backgroud"
                                          />
                                        </div>
                                        <div className="images">
                                          <img
                                            alt={es.subject}
                                            src={
                                              "/file/downloadFile/exhibition/" +
                                              es.photo
                                            }
                                          />
                                        </div>
                                      </div>
                                      <div className="datablock_down">
                                        <h3>{es.subject}</h3>
                                        <ul className="datalist">
                                          <li>
                                            {ReactHtmlParser(
                                              t(
                                                "jumperrwd.exhibition.exhibtionTime2",
                                                {
                                                  onlineDate: es.onlineDate,
                                                  offlineDate: es.offlineDate,
                                                }
                                              )
                                            )}
                                          </li>
                                        </ul>
                                      </div>
                                    </a>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </section>
                      </div>
                    )}
                    {!hasSub && exhibitionIDInt !== 0 && (
                      <>
                        <ResourceListTab
                          resourceType={this.props.resourceType}
                          searchType={searchBlockType}
                          searchForm={this.props.searchForm}
                          {...this.props}
                        />
                        <section className="main_content erm">
                          <div className="container">
                            {this.resourceListComp()}
                          </div>
                        </section>
                      </>
                    )}
                  </>
                );
              }
              return "";
            }}
          </Query>
        </ApolloProvider>
      </>
    );
  }
}

export default ExhibitionComp;
