import React from "react";
import { inject, observer } from "mobx-react";
import { autobind } from "react-decoration";
import navigate from "lib/navigate";
import qs from "query-string";
import TeacherClassReadingQueryGroupComp from "components/teacher/TeacherClassReadingQueryGroupComp";
import TeacherClassReadingInfoComp from "components/teacher/TeacherClassReadingInfoComp";
import TeacherClassReadingAnalyzeComp from "components/teacher/TeacherClassReadingAnalyzeComp";

@inject("appStore")
@observer
class TeacherClassReadingComp extends React.Component {
  constructor(props) {
    super(props);
    let { searchForm } = props;
    let actionType = searchForm.actionType || "classReadingInfo";
    this.state = {
      auth: props.readerStore.auth,
      showFilter: false,
      actionType: actionType,
      year: "",
      semester: "",
    };
  }

  componentDidMount() {}

  UNSAFE_componentWillReceiveProps(props) {}

  @autobind
  changeActionType(actionType) {
    let { location } = this.props;
    let params = {
      pageType: "teacherClassReading",
      actionType: actionType,
    };
    navigate([location.pathname, qs.stringify(params)].join("?"));
  }

  @autobind
  getRefetch(refetch) {
    this.setState({ refetch: refetch });
  }

  @autobind
  changeShowFilter(type) {
    if (type) {
      this.setState({ showFilter: true });
      document.body.classList.add("fix");
    } else {
      this.setState({ showFilter: false });
      document.body.classList.remove("fix");
    }
  }

  @autobind
  setYearAndSemester(year, semester) {
    this.setState({ year: year, semester: semester });
  }

  render() {
    let { searchForm, t } = this.props;
    let { showFilter } = this.state;
    let actionType = searchForm.actionType || "classReadingInfo";
    searchForm.actionType = actionType;

    return (
      <>
        <div className={showFilter ? "filter_search show_up" : "filter_search"}>
          <a
            className="accesskey"
            href="javascript:;"
            id="aL"
            accessKey="L"
            title={t("jumperrwd.common.leftSideArea")}>
            :::
          </a>
          <TeacherClassReadingQueryGroupComp
            ref={(c) => (this.queryGroup = c)}
            {...this.props}
            changeShowFilter={this.changeShowFilter}
            refetch={this.state.refetch}
            showFilter={showFilter}
          />
        </div>
        <div className="search_result">
          {this.state.year !== "" && this.state.semester !== "" ? (
            <h2>
              {this.state.year + "學年度第" + this.state.semester + "學期"}
            </h2>
          ) : (
            <></>
          )}
          <div className="tabSet">
            <ul className="tabs">
              <li className={actionType === "classReadingInfo" ? "active" : ""}>
                <a
                  href="javascript:;"
                  onClick={(e) => {
                    e.preventDefault();
                    this.changeActionType("classReadingInfo");
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      this.changeActionType("classReadingInfo");
                    }
                  }}>
                  班級閱讀情況
                </a>
              </li>
              <li
                className={
                  actionType === "classReadingAnalyze" ? "active" : ""
                }>
                <a
                  href="javascript:;"
                  onClick={(e) => {
                    e.preventDefault();
                    this.changeActionType("classReadingAnalyze");
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      this.changeActionType("classReadingAnalyze");
                    }
                  }}>
                  班級閱讀分析
                </a>
              </li>
            </ul>
          </div>
          {actionType === "classReadingInfo" && (
            <TeacherClassReadingInfoComp
              {...this.props}
              year={this.state.year}
              semester={this.state.semester}
              setYearAndSemester={this.setYearAndSemester}
            />
          )}
          {actionType === "classReadingAnalyze" && (
            <TeacherClassReadingAnalyzeComp
              {...this.props}
              year={this.state.year}
              semester={this.state.semester}
              setYearAndSemester={this.setYearAndSemester}
            />
          )}
        </div>
      </>
    );
  }
}

export default TeacherClassReadingComp;
