import LightBox from "components/common/LightBox";
import client from "lib/ApolloClient";
import React from "react";
import ReactLoading from "react-loading";
import { ApolloProvider, Query } from "react-apollo";
import gql from "graphql-tag";

const getTeacherForStudentClassReading = gql`
  query getTeacherForStudentClassReading($form: TeacherForStudentClassReadingForm) {
    getTeacherForStudentClassReading(Input: $form) {
      studentName
      startDate
      endDate
      readingList {
        title
        lendDate
      }
    }
  }
`;

@LightBox
class TeacherForStudentReadingLightBox extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  UNSAFE_componentWillReceiveProps(props) {}

  render() {
    let { bindingSn = "", userID = "" } = this.props;
    if (bindingSn === "" || userID === "") {
      return <></>;
    }

    return (
      <>
        <h3>已借閱共讀書清單</h3>
        <ApolloProvider client={client.jumperrwdClient}>
          <Query
            displayName="graphql"
            query={getTeacherForStudentClassReading}
            fetchPolicy="network-only"
            variables={{
              form: {
                bindingSn: bindingSn,
                userID: userID,
              }
            }}>
            {({ loading, data, refetch, error }) => {
              if (error) return "";
              if (loading)
                return (
                  <div style={{ textAlign: "center" }}>
                    <ReactLoading
                      type="cylon"
                      height={"10%"}
                      width={"20%"}
                      color="#005D98"
                    />
                  </div>
                );
              if (this.state.refetch === null) {
                this.setState({ refetch: refetch }, () => {
                  if (this.props.getRefetch) {
                    this.props.getRefetch(refetch);
                  }
                });
              }
              if (
                data.getTeacherForStudentClassReading !== null &&
                data.getTeacherForStudentClassReading !== undefined
              ) {
                let {
                  studentName = "",
                  startDate = "",
                  endDate = "",
                  readingList,
                } = data.getTeacherForStudentClassReading;
                return (
                  <>
                    <h3 className="lightor">學生：{studentName}</h3>
                    <div>
                      時間區間：<span className="lightor">{startDate}</span>至
                      <span className="lightor">{endDate}</span>
                    </div>
                    <div className="scroll_content">
                      <div className="table_list">
                        <table className="table_sprite">
                          <tbody>
                            <tr>
                              <th scope="col">&nbsp;</th>
                              <th scope="col">借閱時間</th>
                            </tr>
                            {readingList &&
                              readingList.map((r) => {
                                return (
                                  <tr>
                                    <td>{r.title}</td>
                                    <td>{r.lendDate}</td>
                                  </tr>
                                );
                              })}   
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </>
                );
              }
              return "";
            }}
          </Query>
        </ApolloProvider>
      </>
    );
  }
}

export default TeacherForStudentReadingLightBox;
