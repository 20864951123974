import React from "react";
import ReactLoading from "react-loading";
import client from "lib/ApolloClient";
import { ApolloProvider, Query } from "react-apollo";
import gql from "graphql-tag";
import { inject, observer } from "mobx-react";
import { autobind } from "react-decoration";
import navigate from "lib/navigate";
import qs from "query-string";
import ReactHtmlParser from "react-html-parser";

const getTeacherBindingInfo = gql`
  query getTeacherBindingInfo {
    getTeacherBindingInfo {
      year
      semester
      startDate
      endDate
      classList {
        bindingSn
        schoolID
        grade
        classno
        classTitle
      }
    }
  }
`;

const setTeacherBindClass = gql`
  mutation setTeacherBindClass($form: TeacherBindClassForm) {
    setTeacherBindClass(Input: $form) {
      success
    }
  }
`;

@inject("appStore")
@observer
class TeacherAreaComp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      auth: props.readerStore.auth,
      refetch: null,
      year: "",
      semester: "",
    };
  }

  componentDidMount() {}

  @autobind
  changeActionType(actionType) {
    let { location } = this.props;
    let params = {
      pageType: "teacherBinding",
      actionType: actionType,
    };
    navigate([location.pathname, qs.stringify(params)].join("?"));
  }

  @autobind
  bindClass(form) {
    client.jumperrwdClient
    .mutate({
      mutation: setTeacherBindClass,
      variables: {
        form: form,
      },
    })
    .then((res) => {
      if (res.data.setTeacherBindClass.success) {
        alert("綁定成功");
        if (this.state.refetch) {
          this.state.refetch();
        }
      } else {
        alert("綁定失敗");
      }
    });
  }

  @autobind
  toShareReading(bindingSn) {
    let params = {};
    params.pageType = "teacherShareReading";
    params.listType = "assignedShareReading";
    params.bindingSn = bindingSn
    navigate(["/teacherArea", qs.stringify(params)].join("?"));
  }

  @autobind
  toClassReading(bindingSn) {
    let params = {};
    params.pageType = "teacherClassReading";
    params.actionType = "classReadingInfo";
    params.bindingSn = bindingSn
    navigate(["/teacherArea", qs.stringify(params)].join("?"));
  }

  render() {
    let { searchForm, t } = this.props;
    let actionType = searchForm.actionType || "bindingClass";
    return (
      <ApolloProvider client={client.jumperrwdClient}>
        <div className="filter_search">
          <a
            className="accesskey"
            href="javascript:;"
            id="aL"
            accessKey="L"
            title={this.props.t("jumperrwd.common.leftSideArea")}>
            :::
          </a>
          <h2>教師專區</h2>
          <div className="sort_list">
            <ul>
              <li>
                <div className="list_content">
                  <p>
                    {ReactHtmlParser(t("jumperrwd.tearch.bindingQueryGroupInfo"))}
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div className="search_result">
          <h2>{this.state.year + "學年度第" + this.state.semester + "學期"}</h2>
          <div className="tabSet">
            <ul className="tabs">
              <li className={actionType === "bindingClass" ? "active" : ""}>
                <a
                  href="javascript:;"
                  onClick={(e) => {
                    e.preventDefault();
                    this.changeActionType("bindingClass");
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      this.changeActionType("bindingClass");
                    }
                  }}>
                  授課班級
                </a>
              </li>
            </ul>
          </div>
          <Query
            query={getTeacherBindingInfo}
            fetchPolicy="network-only"
            variables={{
              configType: "page",
            }}>
            {({ loading, error, data, refetch }) => {
              if (loading)
                return (
                  <div style={{ textAlign: "center" }}>
                    <ReactLoading
                      type="cylon"
                      height={"10%"}
                      width={"20%"}
                      color="#005D98"
                    />
                  </div>
                );
              if (error) return `Error!${error.message}`;
              if (this.state.refetch === null) {
                this.setState({ refetch: refetch });
              }

              if (data.getTeacherBindingInfo) {
                let {
                  year = "",
                  semester = "",
                  startDate = "",
                  endDate = "",
                  classList,
                } = data.getTeacherBindingInfo;
                if (
                  this.state.year !== year ||
                  this.state.semester !== semester
                ) {
                  this.setState({ year: year, semester: semester });
                }
                let classInfoList = [];
                if (classList) {
                  classList.map((c) => {
                    let { bindingSn = 0 } = c;
                    classInfoList.push(
                      <li>
                        {t("jumperrwd.schoollist.name." + c.schoolID)}{" "}
                        <span>{c.classTitle}</span>
                        {bindingSn === 0 ? (
                          <a
                            href="javascript:;"
                            className="readbtn"
                            onClick={() => {
                              this.bindClass({
                                year: year,
                                semester: semester,
                                grade: c.grade,
                                classno: c.classno,
                              });
                            }}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                this.bindClass({
                                  year: year,
                                  semester: semester,
                                  grade: c.grade,
                                  classno: c.classno,
                                });
                              }
                            }}>
                            綁定班級
                          </a>
                        ) : (
                          <>
                            <a
                              href="javascript:;"
                              className="readbtn"
                              onClick={() => {
                                this.toShareReading(bindingSn);
                              }}
                              onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  this.toShareReading(bindingSn);
                                }
                              }}>
                              指派共讀書單
                            </a>
                            <a
                              href="javascript:;"
                              className="readbtn"
                              onClick={() => {
                                this.toClassReading(bindingSn);
                              }}
                              onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  this.toClassReading(bindingSn);
                                }
                              }}>
                              班級閱讀情況
                            </a>
                          </>
                        )}
                      </li>
                    );
                    return "";
                  });
                }
                return (
                  <>
                    <div className="function_panel">
                      <div className="result_amount">
                        <div className="class_result">
                          班級總數：共
                          <em>
                            <b>{classInfoList.length}</b>
                          </em>
                          班
                        </div>
                        <div className="class_result">
                          時間區間：
                          <em>
                            <b>{startDate}</b>
                          </em>
                          至
                          <em>
                            <b>{endDate}</b>
                          </em>
                        </div>
                      </div>
                    </div>
                    {classInfoList.length > 0 ? (
                      <>
                        <div className="list_all">
                          <ul className="class_items">{classInfoList}</ul>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="detail_block">
                          <div className="binding_error">
                            <p>
                              {ReactHtmlParser(t("jumperrwd.tearch.noBindingInfo"))}
                            </p>
                          </div>
                        </div>
                      </>
                    )}
                  </>
                );
              }
              return "";
            }}
          </Query>
        </div>
      </ApolloProvider>
    );
  }
}

export default TeacherAreaComp;
