import { autobind } from "react-decoration";
import LightBox from "components/common/LightBox";
import client from "lib/ApolloClient";
import React from "react";
import ReactLoading from "react-loading";
import { ApolloProvider, Query } from "react-apollo";
import gql from "graphql-tag";

const getTeacherAssignClassForJournal = gql`
  query getTeacherAssignClassForJournal($sn: Int) {
    getTeacherAssignClassForJournal(sn: $sn) {
      classList {
        classSn
        schoolID
        classTitle
        assigned
      }
    }
  }
`;

const setTeacherAssignJournalToClass = gql`
  mutation setTeacherAssignJournalToClass($form: TeacherAssignJournalToClassForm) {
    setTeacherAssignJournalToClass(Input: $form) {
      success
      message
    }
  }
`;


@LightBox
class TeacherShareReadingAssignLightBox extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      readingSn: "",
      listType: "",
      allClass: [],
      selectedClass: [],
    };
  }

  UNSAFE_componentWillReceiveProps(props) {
    let listType = props.searchForm.listType || "allShareReading";
    if (props.readingSn !== this.state.readingSn || listType !== this.state.listType) {
      this.setState({
        readingSn: props.readingSn,
        listType: listType,
        allClass: [],
        selectedClass: [],
      });
    }
  }

  @autobind
  selectAll(e) {
    e.preventDefault();
    this.setState({ selectedClass: this.state.allClass });
  }

  @autobind
  addCheckedId(e) {
    e.preventDefault();
    var id = parseInt(e.target.value);
    var c = this.state.selectedClass;
    if (e.target.checked) {
      c.push(id);
    } else {
      var index = c.indexOf(id);
      if (index !== -1) {
        c.splice(index, 1);
      }
    }

    this.setState({ selectedClass: c });
  }

  @autobind
  submitBind(e) {
    e.preventDefault();
    let { readingSn = "", t } = this.props;
    let { selectedClass } = this.state;
    let readingSnInt = parseInt(readingSn);
    client.jumperrwdClient
      .mutate({
        mutation: setTeacherAssignJournalToClass,
        variables: {
          form: {
            readingSn: readingSnInt,
            classSnList: selectedClass
          }
        },
      })
      .then((res) => {
        if (res.data.setTeacherAssignJournalToClass.success) {
          alert("分配成功");
          if (this.props.refetch) {
            this.props.refetch();
          }
          if (this.props.close) {
            this.props.close();
          }
        } else {
          alert("分配失敗");
        }
      });
  }

  render() {
    let { t, readingSn = ""  } = this.props;
    if (readingSn === "") {
      return <></>;
    }
    let readingSnInt = parseInt(readingSn);
    if (readingSnInt === 0) {
      return <></>;
    }

    return (
      <>
        <h3>
          分配至班級：<button tabIndex="0" className="btn btn-submit" onClick={this.selectAll}>全選</button>
        </h3>
        <ApolloProvider client={client.jumperrwdClient}>
          <Query
            displayName="graphql"
            query={getTeacherAssignClassForJournal}
            fetchPolicy="network-only"
            variables={{
              sn: readingSnInt,
            }}>
            {({ loading, data, refetch, error }) => {
              if (error) return "";
              if (loading)
                return (
                  <div style={{ textAlign: "center" }}>
                    <ReactLoading
                      type="cylon"
                      height={"10%"}
                      width={"20%"}
                      color="#005D98"
                    />
                  </div>
                );
              if (this.state.refetch === null) {
                this.setState({ refetch: refetch }, () => {
                  if (this.props.getRefetch) {
                    this.props.getRefetch(refetch);
                  }
                });
              }
              if (
                data.getTeacherAssignClassForJournal !== null &&
                data.getTeacherAssignClassForJournal !== undefined
              ) {
                let { classList } = data.getTeacherAssignClassForJournal;
                let classItem = [];
                let allClass = [];
                let assignedClass = [];
                if (classList) {
                  classList.map((c) => {
                    allClass.push(c.classSn);
                    if (c.assigned) {
                      assignedClass.push(c.classSn);
                    }
                    classItem.push(
                      <td data-title="">
                        <input
                          title="選擇班級"
                          type="checkbox"
                          value={c.classSn}
                          checked={this.state.selectedClass.includes(c.classSn)}
                          onChange={this.addCheckedId}
                        />
                        {t("jumperrwd.schoollist.name." + c.schoolID) +
                          " " +
                          c.classTitle}
                      </td>
                    );
                    return "";
                  });
                }
                if (this.state.allClass.length === 0 && allClass.length !== 0) {
                  this.setState({
                    allClass: allClass,
                    selectedClass: assignedClass
                  })
                }
                return (
                  <div className="scroll_content">
                    <div className="table_list">
                      <table className="table_sprite">
                        <tbody>
                          <tr>{classItem}</tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                );
              }
              return "";
            }}
          </Query>
        </ApolloProvider>
        <div className="btn_grp">
          <button
            tabIndex="0"
            className="btn btn-del"
            onClick={(e) => {
              e.preventDefault();
              this.props.close();
            }}>
            {t("jumperrwd.common.cancel")}
          </button>
          <button tabIndex="0" className="btn btn-submit" onClick={this.submitBind}>
            確定分配
          </button>
        </div>
      </>
    );
  }
}

export default TeacherShareReadingAssignLightBox;
