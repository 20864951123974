import React from "react";
import LightBox from "components/common/LightBox";

@LightBox
class JournalApiActMessageLightBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  afterClose = () => {
    let { actClose } = this.props;
    if (actClose) {
      this.props.actClose();
    }
    return true;
  };

  render() {
    let { apiActMessageItem } = this.props;
    
    if (!apiActMessageItem) {
      return <></>;
    }

    let { title, borrowInfo, reserveInfo } = apiActMessageItem;
    return (
      <>
        <div className="reminderblock">
          {title && <h3>{title}</h3>}
          {borrowInfo && (
            <p className="appointment">
              {borrowInfo.lendNum !== "" && (
                <>
                  此本書尚可借
                  <span className="orangetxt">{borrowInfo.lendNum}</span>冊
                </>
              )}
              {borrowInfo.copyNum !== "" && <>/共{borrowInfo.copyNum}冊</>}
              {borrowInfo.reserveNum !== "" && (
                <>
                  <span className="people">預約人數</span>
                  <span className="greentxt">{borrowInfo.reserveNum}</span>人
                </>
              )}
            </p>
          )}
          {reserveInfo && (
            <p className="appointment">
              {reserveInfo.reserveSort !== "" && (
                <>
                  您的預約排序為第
                  <span className="greentxt">{reserveInfo.reserveSort}</span>順位
                </>
              )}
            </p>
          )}
          <div className="btn_grp">
            <button
              className="btn btn-submit"
              tabIndex="0"
              onClick={this.props.close}>
              確定
            </button>
          </div>
        </div>
      </>
    );
  }
}

export default JournalApiActMessageLightBox;
