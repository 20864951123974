import React from "react";
import PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import gql from "graphql-tag";
import { arrangeData } from "components/list/action/action-util";
import View from "components/list/view/View";
import Link from "lib/Link";
import ReactHtmlParser from "react-html-parser";
import { autobind } from "react-decoration";
import client from "lib/ApolloClient";
import qs from "query-string";

const setTeacherNetworkRecommend = gql`
  mutation setTeacherNetworkRecommend($form: TeacherNetworkRecommendForm) {
    setTeacherNetworkRecommend(Input: $form) {
      success
    }
  }
`;

@inject("readerStore", "appStore")
@observer
class TeacherNetworkRecommendDisplayComp extends View {
  constructor(props) {
    super(props);

    this.state = {};
  }

  @autobind
  networkRecommend(form) {
    if (!form.title) {
      alert("未有題名");
    } else {
      client.jumperrwdClient
        .mutate({
          mutation: setTeacherNetworkRecommend,
          variables: {
            form: form,
          },
        })
        .then((res) => {
          if (res.data.setTeacherNetworkRecommend.success) {
            alert("推薦成功");
            this.clearAll();
          } else {
            alert("推薦失敗");
          }
        });
    }
  }

  render() {
    let { data, rowStyle, serialNo, t } = this.props;

    const arrangedData = arrangeData(data);

    let {
      title,
      author,
      date,
      photo = "",
      standardno,
      volume,
      issue,
      page,
      urllink,
      index,
      source,
      isbn,
      eStandardno,
      publisher,
      db,
    } = arrangedData;

    title = title || "(無題名)";

    let params = {
      url: urllink,
    };

    if (urllink.trim() !== "") {
      urllink = ["/sendURLApiV3", qs.stringify(params)].join("?");
    } else {
      urllink = "";
    }

    var values = [];
    data.map((item) => {
      values.push({
        key: item.key,
        value: item.value,
      });
      return "";
    });

    if (photo === "") {
      photo = "/file/images/icon_book.svg";
    }

    /*
    return   <>
      <div className={`list_block ${rowStyle}`}>
        <div className="list_num">{serialNo} {title}</div> 
        <div className="sort_icon">
          <div className="pic">
            <img src={photo} onError={(e)=>{e.target.src="/file/images/icon_book.svg"}} />
          </div>
          <div className="sort_name"></div>
        </div>
      </div> 
    </>*/

    return (
      <>
        {/*rowStyle&&<div>{rowStyle}</div>*/}
        <div className={`list_block ${rowStyle}`}>
          <div className="list_num">{serialNo}</div>
          <div className="sort_icon">
            <div className="pic">
              <img
                src={photo}
                alt={t("jumperrwd.common.book")}
                onError={(e) => {
                  e.target.src = "/file/images/icon_book.svg";
                }}
              />
            </div>
            <div className="sort_name"></div>
          </div>
          <div className="list_data">
            <h3>
              {urllink && (
                <Link href={urllink} target="_BLANK">
                  {ReactHtmlParser(title)}
                </Link>
              )}
              {!urllink && ReactHtmlParser(title)}
            </h3>
            <ul>
              <li>
                {standardno && `issn. ${standardno} `}
                {volume && `vol. ${volume} `}
                {issue && `issue. ${issue} `}
                {page && `pp. ${page} `}
              </li>
              {author && (
                <li>
                  <div className="title">{t("jumperrwd.common.author")}：</div>
                  <div>{author}</div>
                </li>
              )}
              {publisher && (
                <li>
                  <div className="title">{t("jumperrwd.common.publisher")}：</div>
                  <div>{publisher}</div>
                </li>
              )}
              {index && (
                <li>
                  <div className="title">
                    {t("jumperrwd.common.callnumber")}：
                  </div>
                  {index}
                </li>
              )}
              {source && (
                <li>
                  <div className="title">{t("jumperrwd.common.source")}：</div>
                  {source}
                </li>
              )}
              {isbn && (
                <li>
                  <div className="title">isbn：</div>
                  {isbn}
                </li>
              )}
              {eStandardno && (
                <li>
                  <div className="title">eissn：</div>
                  {eStandardno}
                </li>
              )}
              {date && (
                <li>
                  <div className="title">
                    {t("jumperrwd.common.publishDate")}：
                  </div>
                  <div>{date}</div>
                </li>
              )}
              <li>
                <div>
                  <button
                    className="btn_borrow"
                    type="button"
                    onClick={(e) => {
                      e.preventDefault();
                      let form = {
                        title: title,
                        author: author,
                        isbn: isbn,
                        publisher: publisher,
                        publishDate: date,
                        dbid: db,
                      }
                      this.networkRecommend(form);
                    }}>
                    <a tabindex="0">我要推薦</a>
                  </button>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </>
    );
  }
}

TeacherNetworkRecommendDisplayComp.propTypes = {
  /**
   * The layout define display config is used to define layout.
   * The datasoure is from LayoutReturnResult.display.
   */
  displayConfig: PropTypes.array.isRequired,

  /** td content */
  data: PropTypes.array,

  /** layout define's func, record link or button behavior. */
  functionConfigList: PropTypes.array,

  /** layout define's func_right, record link or button behavior. */
  functionRightConfigList: PropTypes.array,

  /** layout define's left_right, record link or button behavior. */
  functionLeftConfigList: PropTypes.array,

  /** layout define's detail */
  detailList: PropTypes.object,

  /** button events */
  buttonEvent: PropTypes.func,

  /**
   * batch config
   */
  batchConfig: PropTypes.array,

  /**
   * serialNo
   */
  serialNo: PropTypes.number,

  /**
   * checked the row or not;
   */
  checked: PropTypes.bool,

  /**
   * row style className
   */
  rowStyle: PropTypes.string,
};

TeacherNetworkRecommendDisplayComp.defaultProps = {
  buttonEvent: () => {},
  functionConfigList: [],
  functionRightConfigList: [],
  functionLeftConfigList: [],
  detailList: [],
  batchConfig: [],
  serialNo: -1,
  checked: false,
  rowStyle: "",
};

export default TeacherNetworkRecommendDisplayComp;
