import React from "react";
import { inject, observer } from "mobx-react";
import { autobind } from "react-decoration";
import navigate from "lib/navigate";
import qs from "query-string";
import axios from "axios";
import TeacherNetworkRecommendEbookComp from "components/teacher/TeacherNetworkRecommendEbookComp";
import TeacherInputRecommendEbookComp from "components/teacher/TeacherInputRecommendEbookComp";
import TeacherBatchRecommendEbookComp from "components/teacher/TeacherBatchRecommendEbookComp";
import ReactHtmlParser from "react-html-parser";

@inject("appStore")
@observer
class TeacherRecommendEbookComp extends React.Component {
  constructor(props) {
    super(props);
    let { searchForm } = props;
    let actionType = searchForm.actionType || "networkRecommend";
    this.state = {
      auth: props.readerStore.auth,
      refetch: null,
      actionType: actionType,
      agreeCheck: false,
    };
  }

  componentDidMount() {}

  UNSAFE_componentWillReceiveProps(props) {}

  @autobind
  changeActionType(actionType) {
    let { location } = this.props;
    let params = {
      pageType: "teacherRecommendEbook",
      actionType: actionType,
    };
    navigate([location.pathname, qs.stringify(params)].join("?"));
  }

  @autobind
  submitAgree(e) {
    e.preventDefault();
    let { agreeCheck } = this.state;
    if (agreeCheck) {
      this.props.appStore.setAgreeTeacherArea(1);
      axios
        .get("/api/jumperrwdWs/setSession?key=agreeTeacherRecommend&value=1")
        .then((res) => {
          this.props.readerStore.syncSessionCookie().then(() => {
            if (this.props.close) {
              this.props.close();
            }
          });
        });
    } else {
      alert("請打勾同意內容");
    }
  }

  render() {
    let { searchForm, readerStore, appStore, t } = this.props;
    let { agreeCheck } = this.state;
    let actionType = searchForm.actionType || "networkRecommend";
    let { agreeTeacherRecommend } = appStore;
    let { session } = readerStore;
    let showAgreeBlock =
      session.agreeTeacherRecommend !== "1" && agreeTeacherRecommend !== 1;

    return (
      <>
        <div className="filter_search">
          <div className="close" tabindex="0"></div>
          <h2>薦購電子書</h2>
          <div className="sort_list">
            <p>
              {ReactHtmlParser(t("jumperrwd.tearch.recommendQueryGroupInfo"))}
            </p>
          </div>
          <p>
            {ReactHtmlParser(t("jumperrwd.tearch.recommendQueryGroupInfo2"))}
          </p>
        </div>
        <div className="search_result">
          <h2>薦購</h2>
          <div className="tabSet">
            <ul className="tabs">
              <li className={actionType === "networkRecommend" ? "active" : ""}>
                <a
                  href="javascript:;"
                  onClick={(e) => {
                    e.preventDefault();
                    this.changeActionType("networkRecommend");
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      this.changeActionType("networkRecommend");
                    }
                  }}>
                  查詢薦購
                </a>
              </li>
              <li className={actionType === "inputRecommend" ? "active" : ""}>
                <a
                  href="javascript:;"
                  onClick={(e) => {
                    e.preventDefault();
                    this.changeActionType("inputRecommend");
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      this.changeActionType("inputRecommend");
                    }
                  }}>
                  輸入薦購
                </a>
              </li>
              <li className={actionType === "batchRecommend" ? "active" : ""}>
                <a
                  href="javascript:;"
                  onClick={(e) => {
                    e.preventDefault();
                    this.changeActionType("batchRecommend");
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      this.changeActionType("batchRecommend");
                    }
                  }}>
                  批次薦購
                </a>
              </li>
            </ul>
          </div>
          {showAgreeBlock && (
            <div className="list_all">
              <div className="detail_block">
                <div className="info">
                  {ReactHtmlParser(t("jumperrwd.tearch.recommendNotification"))}
                </div>
                <div className="questionnaire">
                  <hr />
                  <input
                    type="checkbox"
                    name="agreeCheck"
                    value="1"
                    checked={agreeCheck}
                    onChange={(e) => {
                      this.setState({ agreeCheck: !agreeCheck });
                    }}
                  />
                  本人已詳閱並同意上述內容。
                  <div className="btn_grp">
                    <button
                      className="btn btn-reset"
                      onClick={(e) => {
                        e.preventDefault();
                        navigate("/teachArea");
                      }}>
                      取消
                    </button>
                    <button
                      className="btn btn-submit"
                      onClick={this.submitAgree}>
                      確認
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
          {!showAgreeBlock && (
            <>
              {actionType === "networkRecommend" && (
                <TeacherNetworkRecommendEbookComp {...this.props} />
              )}
              {actionType === "inputRecommend" && (
                <TeacherInputRecommendEbookComp {...this.props} />
              )}
              {actionType === "batchRecommend" && (
                <TeacherBatchRecommendEbookComp {...this.props} />
              )}
            </>
          )}
        </div>
      </>
    );
  }
}

export default TeacherRecommendEbookComp;
