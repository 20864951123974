import React from "react";
import client from "lib/ApolloClient";
import { ApolloProvider, Query } from "react-apollo";
import gql from "graphql-tag";
import { autobind } from "react-decoration";
import qs from "query-string";
import navigate from "lib/navigate";
import { inject, observer } from "mobx-react";
import ReactHtmlParser from "react-html-parser";
import ResourceGroupLightBox from "components/resource/ResourceGroupLightBox";

const getStudentShareReadingQueryGroup = gql`
  query getStudentShareReadingQueryGroup($searchForm: SearchForm) {
    getStudentShareReadingQueryGroup(Input: $searchForm) {
      queryGroupList {
        title
        index
        list {
          cnt
          title
          value
        }
      }
      dbList {
        resourceID
        ebName
        enEbName
        jo2DBCount
      }
      srList {
        sr
        jo2SRCount
      }
      showDBNum
    }
  }
`;

@inject("appStore")
@observer
class StudentShareReadingQueryGroupComp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      auth: props.readerStore.auth,
      refetch: null,
      closeList: [],
    };
  }

  @autobind
  setFilter(ff, fv, e) {
    e.preventDefault();
    if (ff !== "" && fv !== "") {
      let { search, location } = this.props;
      let params = { ...search };
      let { filterField, filterValue } = params;
      filterField = [].concat(filterField || []);
      filterValue = [].concat(filterValue || []);
      filterField.push(ff);
      filterValue.push(fv);
      params.filterField = filterField;
      params.filterValue = filterValue;
      delete params.pageNo;
      navigate([location.pathname, qs.stringify(params)].join("?"));
    }
  }

  @autobind
  delAllFilter(e) {
    e.preventDefault();
    let { search, location } = this.props;
    let params = { ...search };
    delete params.filterField;
    delete params.filterValue;
    delete params.pageNo;
    navigate([location.pathname, qs.stringify(params)].join("?"));
  }

  @autobind
  delFilter(field, value, e) {
    e.preventDefault();
    let { search, location } = this.props;
    let params = { ...search };
    let { filterField, filterValue } = params;
    filterField = [].concat(filterField || []);
    filterValue = [].concat(filterValue || []);
    let newFilterField = [];
    let newFilterValue = [];
    filterField.map((v, k) => {
      if (v !== field || filterValue[k] !== value) {
        newFilterField.push(v);
        newFilterValue.push(filterValue[k]);
      }
      return "";
    });
    if (newFilterField.length > 0) {
      params.filterField = newFilterField;
      params.filterValue = newFilterValue;
    } else {
      delete params.filterField;
      delete params.filterValue;
    }
    navigate([location.pathname, qs.stringify(params)].join("?"));
  }

  @autobind
  makeFilterMap(filterField, filterValue) {
    let filterMap = {};
    let filterFieldList = [].concat(filterField || []);
    let filterValueList = [].concat(filterValue || []);
    filterFieldList.map((field, k) => {
      if (field === "class") {
        let values = filterValueList[k].split(":");
        let filterClassMap = filterMap.class || {};
        let filterClassList = [].concat(filterMap.classList || []);
        filterClassList.push(filterValueList[k]);
        let valueList = [].concat(filterClassMap[values[0]] || []);
        valueList.push(values[1]);
        filterClassMap[values[0]] = valueList;
        filterMap.class = filterClassMap;
        filterMap.classList = filterClassList;
      } else {
        let valueList = [].concat(filterMap[field] || []);
        valueList.push(filterValueList[k]);
        filterMap[field] = valueList;
      }
      return "";
    });
    return filterMap;
  }

  @autobind
  changeQueryGroupClose(isClose, name, e) {
    e.preventDefault();
    if (isClose) {
      var closeList = [...this.state.closeList];
      var index = closeList.indexOf(name);
      if (index !== -1) {
        closeList.splice(index, 1);
        this.setState({ closeList });
      }
    } else {
      let closeList = [...this.state.closeList];
      closeList.push(name);
      this.setState({ closeList });
    }
  }

  render() {
    let { t, searchForm, i18n } = this.props;
    let { language } = i18n;
    let listType = searchForm.listType || "shareReading";
    let { filterField, filterValue } = searchForm;
    let filterMap = this.makeFilterMap(filterField, filterValue);
    let filterFieldList = [].concat(filterField || []);
    let classType = "ebookclass_dir";
    searchForm.listType = listType;
    return (
      <>
        <div
          className="close"
          tabIndex="0"
          onClick={this.props.changeShowFilter.bind(this, false)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              this.props.changeShowFilter(false, e);
            }
          }}></div>
        <h2>{t("jumperrwd.common.filterCondition")}</h2>
        <div className="sort_list">
          <ul>
            <ApolloProvider client={client.jumperrwdClient}>
              <Query
                displayName="graphql"
                fetchPolicy="network-only"
                query={getStudentShareReadingQueryGroup}
                variables={{
                  searchForm: searchForm,
                }}>
                {({ loading, data, refetch, error }) => {
                  if (error) return "";
                  if (loading) return "Loading...";

                  if (this.state.refetch === null)
                    this.setState({ refetch: refetch });

                  if (data.getStudentShareReadingQueryGroup) {
                    let {
                      queryGroupList,
                      dbList,
                      srList,
                      showDBNum = 5,
                    } = data.getStudentShareReadingQueryGroup;
                    let classFilterList = [].concat(filterMap.classList || []);
                    let dbFilterList = [].concat(filterMap.db || []);
                    let srFilterList = [].concat(filterMap.sr || []);
                    let dbItemMap = {};
                    let dbShowFlag = 0;
                    if (dbList !== null && dbList !== undefined) {
                      dbList.map((dbItem) => {
                        dbItemMap[dbItem.resourceID] = dbItem;
                        return "";
                      });
                    }
                    return (
                      <>
                        {(filterFieldList.length > 0) && (
                          <div className="conditions">
                            <h3>{t("jumper.common.limiter.tips")}</h3>
                            <button
                              type="button"
                              tabIndex="0"
                              className="del_all"
                              onClick={this.delAllFilter.bind(this)}>
                              {t("jumperrwd.common.clearAll")}
                            </button>
                            <ul>
                              {Object.keys(filterMap).map((key) => {
                                if (key === "source") {
                                  return (
                                    <li>
                                      <h4>
                                        {t("jumper.common.limiter.status")}
                                      </h4>
                                      {filterMap[key].map((value) => {
                                        let name = "";
                                        if (value === "jcr") {
                                          name = t("jumper.common.limiter.jcr");
                                        } else if (value === "news") {
                                          name = t(
                                            "jumper.common.limiter.news"
                                          );
                                        }
                                        return (
                                          <ul>
                                            <li>
                                              <button
                                                type="button"
                                                tabIndex="0"
                                                className="del_btn"
                                                onClick={this.delFilter.bind(
                                                  this,
                                                  "source",
                                                  value
                                                )}>
                                                {t("jumperrwd.common.delete")}
                                              </button>
                                              <span>
                                                {ReactHtmlParser(name)}
                                              </span>
                                            </li>
                                          </ul>
                                        );
                                      })}
                                    </li>
                                  );
                                } else if (key === "class") {
                                  let list = [];
                                  Object.keys(filterMap.class).map((key2) => {
                                    list.push(
                                      <li>
                                        <h4>
                                          {t(
                                            "jumperrwd." +
                                              classType +
                                              ".name." +
                                              key2
                                          )}
                                        </h4>
                                        {filterMap.class[key2].map((value) => {
                                          return (
                                            <ul>
                                              <li>
                                                <button
                                                  type="button"
                                                  tabIndex="0"
                                                  className="del_btn"
                                                  onClick={this.delFilter.bind(
                                                    this,
                                                    "class",
                                                    key2 + ":" + value
                                                  )}>
                                                  {t("jumperrwd.common.delete")}
                                                </button>
                                                <span>
                                                  {ReactHtmlParser(
                                                    t(
                                                      "jumperrwd." +
                                                        classType +
                                                        ".name." +
                                                        value
                                                    )
                                                  )}
                                                </span>
                                              </li>
                                            </ul>
                                          );
                                        })}
                                      </li>
                                    );
                                    return "";
                                  });
                                  return list;
                                } else if (key === "db") {
                                  return (
                                    <li>
                                      <h4>{t("jumperrwd.common.database")}</h4>
                                      {filterMap[key].map((value) => {
                                        let dbItem = dbItemMap[value];
                                        if (dbItem) {
                                          let dbName =
                                            language === "en" &&
                                            dbItem.enEbName !== ""
                                              ? dbItem.enEbName
                                              : dbItem.ebName;
                                          return (
                                            <ul>
                                              <li>
                                                <button
                                                  type="button"
                                                  tabIndex="0"
                                                  className="del_btn"
                                                  onClick={this.delFilter.bind(
                                                    this,
                                                    "db",
                                                    value
                                                  )}>
                                                  {t("jumperrwd.common.delete")}
                                                </button>
                                                <span>
                                                  {ReactHtmlParser(dbName)}
                                                </span>
                                              </li>
                                            </ul>
                                          );
                                        } else {
                                          return <></>;
                                        }
                                      })}
                                    </li>
                                  );
                                } else if (key === "sr") {
                                  return (
                                    <li>
                                      <h4>{t("jumperrwd.common.sr")}</h4>
                                      {filterMap[key].map((value) => {
                                        if (value !== "") {
                                          return (
                                            <ul>
                                              <li>
                                                <button
                                                  type="button"
                                                  tabIndex="0"
                                                  className="del_btn"
                                                  onClick={this.delFilter.bind(
                                                    this,
                                                    "sr",
                                                    value
                                                  )}>
                                                  {t("jumperrwd.common.delete")}
                                                </button>
                                                <span>
                                                  {t("jumperrwd.sr." + value)}
                                                </span>
                                              </li>
                                            </ul>
                                          );
                                        } else {
                                          return <></>;
                                        }
                                      })}
                                    </li>
                                  );
                                }
                                return <></>;
                              })}
                            </ul>
                          </div>
                        )}
                        {
                          <div className="sort_list">
                            <ul>
                              {dbList !== null &&
                                dbList !== undefined &&
                                dbList.length > 0 && (
                                  <li>
                                    <h3 className="turnicon">
                                      {t("jumperrwd.common.database")}
                                    </h3>
                                    <div className="accordion_content">
                                      {(() => {
                                        let dbDataList = [];
                                        let dbOut = [];
                                        dbOut.push(
                                          dbList.map((dbItem) => {
                                            let dbName =
                                              language === "en" &&
                                              dbItem.enEbName !== ""
                                                ? dbItem.enEbName
                                                : dbItem.ebName;
                                            let dbData = {
                                              title: dbName,
                                              cnt: dbItem.jo2DBCount,
                                              value: dbItem.resourceID,
                                            };
                                            dbDataList.push(dbData);
                                            if (
                                              !dbFilterList.includes(
                                                dbItem.resourceID
                                              )
                                            ) {
                                              dbShowFlag++;
                                              if (dbShowFlag <= showDBNum) {
                                                return (
                                                  <>
                                                    <ul>
                                                      <li>
                                                        <a
                                                          tabIndex="0"
                                                          onClick={this.setFilter.bind(
                                                            this,
                                                            "db",
                                                            dbItem.resourceID
                                                          )}
                                                          onKeyDown={(e) => {
                                                            if (
                                                              e.key === "Enter"
                                                            ) {
                                                              this.setFilter(
                                                                "db",
                                                                dbItem.resourceID,
                                                                e
                                                              );
                                                            }
                                                          }}>
                                                          {ReactHtmlParser(
                                                            dbName
                                                          ) +
                                                            "(" +
                                                            dbItem.jo2DBCount +
                                                            ")"}
                                                        </a>
                                                      </li>
                                                    </ul>
                                                  </>
                                                );
                                              }
                                            }
                                            return "";
                                          })
                                        );
                                        if (dbShowFlag > showDBNum) {
                                          dbOut.push(
                                            <>
                                              <button
                                                type="button"
                                                className="more"
                                                tabIndex="0"
                                                onClick={this.openResourceGroupLightBox.bind(
                                                  this,
                                                  "db"
                                                )}>
                                                {t("jumperrwd.common.seeMore")}
                                              </button>
                                              <ResourceGroupLightBox
                                                ref="dbGroupLightBox"
                                                {...this.props}
                                                title={t(
                                                  "jumperrwd.common.database"
                                                )}
                                                index={"db"}
                                                data={dbDataList}
                                                lightboxClass={
                                                  "filter_lightbox"
                                                }
                                                blockClass={"checkblock"}
                                              />
                                            </>
                                          );
                                        }
                                        return dbOut;
                                      })()}
                                    </div>
                                  </li>
                                )}
                              {srList !== null &&
                                srList !== undefined &&
                                srList.length > 0 && (
                                  <li>
                                    <h3 className="turnicon">
                                      {t("jumperrwd.common.sr")}
                                    </h3>
                                    <div className="accordion_content">
                                      {(() => {
                                        let srDataList = [];
                                        let srOut = [];
                                        srOut.push(
                                          srList.map((srItem) => {
                                            let srData = {
                                              cnt: srItem.jo2DBCount,
                                              value: srItem.sr,
                                            };
                                            srDataList.push(srData);
                                            if (
                                              !srFilterList.includes(srItem.sr)
                                            ) {
                                              return (
                                                <>
                                                  <ul>
                                                    <li>
                                                      <a
                                                        tabIndex="0"
                                                        onClick={this.setFilter.bind(
                                                          this,
                                                          "sr",
                                                          srItem.sr
                                                        )}
                                                        onKeyDown={(e) => {
                                                          if (
                                                            e.key === "Enter"
                                                          ) {
                                                            this.setFilter(
                                                              "sr",
                                                              srItem.sr,
                                                              e
                                                            );
                                                          }
                                                        }}>
                                                        {t(
                                                          "jumperrwd.sr." +
                                                            srItem.sr
                                                        ) +
                                                          "(" +
                                                          srItem.jo2SRCount +
                                                          ")"}
                                                      </a>
                                                    </li>
                                                  </ul>
                                                </>
                                              );
                                            }
                                            return "";
                                          })
                                        );
                                        return srOut;
                                      })()}
                                    </div>
                                  </li>
                                )}
                              {queryGroupList !== null &&
                                queryGroupList !== undefined &&
                                queryGroupList.map((queryGroup) => {
                                  let isClose = this.state.closeList.includes(
                                    "class:" +
                                      classType +
                                      ":" +
                                      queryGroup.index
                                  );
                                  return (
                                    <>
                                      <li>
                                        <h3>
                                          <a
                                            tabIndex="0"
                                            className={
                                              isClose ? "" : "turnicon"
                                            }
                                            onClick={this.changeQueryGroupClose.bind(
                                              this,
                                              isClose,
                                              "class:" +
                                                classType +
                                                ":" +
                                                queryGroup.index
                                            )}
                                            onKeyDown={(e) => {
                                              if (e.key === "Enter") {
                                                this.changeQueryGroupClose(
                                                  isClose,
                                                  "class:" +
                                                    classType +
                                                    ":" +
                                                    queryGroup.index,
                                                  e
                                                );
                                              }
                                            }}>
                                            {t(
                                              "jumperrwd." +
                                                classType +
                                                ".name." +
                                                queryGroup.index
                                            )}
                                          </a>
                                        </h3>
                                        {!isClose && (
                                          <div className="accordion_content">
                                            {queryGroup.list !== null &&
                                              queryGroupList !== undefined &&
                                              queryGroup.list.map((list) => {
                                                if (
                                                  !classFilterList.includes(
                                                    queryGroup.index +
                                                      ":" +
                                                      list.value
                                                  )
                                                ) {
                                                  return (
                                                    <>
                                                      <ul>
                                                        <li>
                                                          <a
                                                            tabIndex="0"
                                                            onClick={this.setFilter.bind(
                                                              this,
                                                              "class",
                                                              queryGroup.index +
                                                                ":" +
                                                                list.value
                                                            )}
                                                            onKeyDown={(e) => {
                                                              if (
                                                                e.key ===
                                                                "Enter"
                                                              ) {
                                                                this.setFilter(
                                                                  "class",
                                                                  queryGroup.index +
                                                                    ":" +
                                                                    list.value,
                                                                  e
                                                                );
                                                              }
                                                            }}>
                                                            {ReactHtmlParser(
                                                              t(
                                                                "jumperrwd." +
                                                                  classType +
                                                                  ".name." +
                                                                  list.value
                                                              )
                                                            ) +
                                                              "(" +
                                                              list.cnt +
                                                              ")"}
                                                          </a>
                                                        </li>
                                                      </ul>
                                                    </>
                                                  );
                                                }
                                                return "";
                                              })}
                                          </div>
                                        )}
                                      </li>
                                    </>
                                  );
                                })}
                            </ul>
                          </div>
                        }
                      </>
                    );
                  }
                  return "";
                }}
              </Query>
            </ApolloProvider>
          </ul>
        </div>
      </>
    );
  }
}

export default StudentShareReadingQueryGroupComp;
